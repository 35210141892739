"use strict";

let GC = {
  resolution: 1080,

  dedicatedWidth: (6900 - 2.0) * (3 / 6) ,

  columnWidth: (6900 - 2.0) * 1,

  regionWidth: (6900 - 2.0) * 2,

  layerSpeeds: [(6900 - 2.0)/(1478 - 2.0), (3104 - 2.0)/(1478 - 2.0), (2000 - 2.0)/(1478 - 2.0), (1478 - 2.0)/(1478 - 2.0)],

  layerWidths: [ (6900 - 2.0), (3104 - 2.0), (2000 - 2.0), (1478 - 2.0) ],

  UNLOAD: 'unload',
  LOADING: 'loading',
  LOADED: 'loaded',

  DESTROYED: 'destroyed',
  CREATED: 'created',

  sidebarWidth: 165,

  systemNames: [
    '染织服装艺术设计系', 
    '环境艺术设计系',
    '工艺美术系',
    '基础教研室', // 只有研究生
    '工业设计系',
    '全日制科普创意与设计项目艺术硕士', // 只有研究生
    '绘画系',
    '视觉传达设计系',
    '雕塑系', // 只有研究生
    '艺术史论系', // 只有研究生
    '陶瓷艺术设计系',
    '信息艺术设计系',
  ],

  changePageDuration: 1000,

  onlyGraduate: [3, 5, 8, 9], // 只有研究生的系

  systemMap: {
    '1c5b': 0, // '染织服装艺术设计系',
    'd5df': 1, // '环境艺术设计系',
    '8d2b': 2, // '工艺美术系',
    'ead8': 3, // '基础教研室',
    'acf0': 4, // '工业设计系',
    '2eaf': 5, //'全日制科普创意与设计项目艺术硕士'
    'a8e0': 6, // '绘画系',
    'ea23': 7, // '视觉传达设计系',
    'f18c': 8, // '雕塑系',
    '9bc6': 9, //'艺术史论系',
    'e399': 10, // '陶瓷艺术设计系',
    'c871': 11, // '信息艺术设计系',
  },
};


export default GC;

//console.log(GC);
