'use strict';

var SceneBottomLine = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneBottomLine ()
  {
    Phaser.Scene.call(this, { key: 'sceneBottomLine', active: false });
  },

  init: function(params){
    var self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    // this.sceneBoot.scaleMode = 'height';
    // this.scaler.scaleMode = 'height';

    this.sceneBoot.windowResized = true;
    
    this.scene.bringToTop();

    this.frame = 0;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    var sprite = this.add.sprite(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleBgY(designY, 'bottom'),
      'animation_bg'
    );
    sprite.setOrigin(0.5, 1);
    this.scaler.scaleSpriteFillWidth(sprite);
    this.bgScale = 1080/sprite.height
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'bottom',
      designX: designX,
      designY: designY,
      scaleFillWidth: true,
      scaleBgY: true,
      initialScale: sprite.scale,
    };
    this.bgSprite = sprite;
    sprite.scaleY *= this.bgScale;

    // this.bgSprite.tilePositionY = 1080 - 26;

    const startX = this.bgSprite.height * 26 * this.scaler.vScale()/this.bgSprite.displayHeight;
    this.bgSprite.setCrop(0, this.bgSprite.height - startX, 1920, startX);

    utils.hide(this);

    // this.autoScrollerTimer = this.time.addEvent({
    //   delay: 1000/25,
    //   callback: function(){
    //     this.bgSprite.tilePositionX -= this.scaler.vScale() * 1.4;
    //   },
    //   callbackScope: this,
    //   loop: true
    // });
  },

  update: function() {
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    
    if(this.scaler.width >= this.scaler.height) {
      this.cameras.main.centerOnX(this.scaler.width/2);
    } else {
      this.cameras.main.centerOnX(this.scaler.height/2);
    }

    utils.resized(this);

    this.bgSprite.scaleY = this.bgSprite.keepData.initialScale * this.bgSprite.scaleX;
    const startX = this.bgSprite.height * 26 * this.scaler.vScale()/this.bgSprite.displayHeight;
    this.bgSprite.setCrop(0, this.bgSprite.height - startX, 1920, startX);

    this.resetPosition();
  },

  enterAnimation: function() {
    const self = this;
    this.animationing = true;
    utils.show(this);
    utils.sceneSlideAimation(this, 'in', function() {
      self.animationing = false;
      self.resized();
    });
  },

  outAnimation: function() {
    const self = this;
    this.animationing = true;
    utils.sceneSlideAimation(this, 'out', function() {
      utils.hide(self);
      self.animationing = false;
    });
  },

  leftInAnimation: function() {
    const self = this;
    this.animationing = true;
    utils.show(this);
    utils.sceneSlideAimation(this, 'leftIn', function() {
      self.animationing = false;
      self.resized();
    });
  },

  fadeOutAnimation: function(func) {
    utils.fadeOutAnimation(this, this.cameras.main).then(() => {
      if(func) {
        func();
      }
    });
  },

  changeWidth: function() {
    const sprite = this.bgSprite;

    sprite.keepData.width = 2572;
    sprite.displayWidth = this.scaler.hScale() * 2572;
    sprite.scaleY = sprite.keepData.initialScale * sprite.scaleX;
    const startX = sprite.height * 26 * this.scaler.vScale()/sprite.displayHeight;
    sprite.setCrop(0, sprite.height - startX, 1920, startX);
  },

  changeWidthAnimation: function() {
    const self = this;
    const sprite = this.bgSprite;
    this.tweens.add({
      targets: sprite,
      displayWidth: this.scaler.hScale() * 2572,
      displayHeight: this.scaler.vScale() * 2572 * 1080/1920,
      duration: 1000,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      yoyo: false,
      onUpdate: function() {
        const startX = sprite.height * 26 * self.scaler.vScale()/sprite.displayHeight;
        sprite.setCrop(0, sprite.height - startX, 1920, startX);
      },
      onComplete: function () {
        sprite.keepData.width = 2572;
      },
    });
  },

  widthRecover: function() {
    const sprite = this.bgSprite;
    delete this.bgSprite.keepData.width;
    this.scaler.scaleSpriteFillWidth(sprite);
    sprite.scaleY = sprite.keepData.initialScale * sprite.scaleX;

    const startX = this.bgSprite.height * 26 * this.scaler.vScale()/this.bgSprite.displayHeight;
    sprite.setCrop(0, sprite.height - startX, 1920, startX);

    this.offset = 0;
  },

  scroll: function(step) {
    if(this.bgSprite.x > this.scaler.scaleX(2572/2 - (2572 - 1920))) {
      this.bgSprite.x -= step;
    } 
    
    if(this.bgSprite.x < this.scaler.scaleX(2572/2 - (2572 - 1920))) {
      this.bgSprite.x = this.scaler.scaleX(2572/2 - (2572 - 1920))
    }

    this.offset = this.bgSprite.x - this.scaler.scaleX(this.bgSprite.keepData.designX);
  },

  resetPosition: function() {
    if(this.offset) {
      this.bgSprite.x = this.offset + this.scaler.scaleX(this.bgSprite.keepData.designX);
    }
  },

  show: function() {
    utils.show(this);
  },

  updateFrame: function() {
    this.bgSprite.setTexture('animation_bg_' + animationFrame);
  },
});
export default SceneBottomLine;