import GC from "./const";

'use strict';

var SceneSideBar = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneSideBar ()
  {
    Phaser.Scene.call(this, { key: 'sceneSideBar', active: false });
  },

  init: function(params){
    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'height';
    this.scaler.scaleMode = 'height';

    this.sceneBoot.windowResized = true;

    this.params = params;

    this.scene.bringToTop();
  },

  preload: function ()
  {
  },

  create: function ()
  {
    console.log('sidebar ..');
    this.cameras.main.setVisible(false);

    var self = this;
    var designX, designY, sprite;

    var source = 'sidebar_bg_' + utils.getColor();
    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      source
    );
    sprite.setOrigin(0, 0.5);
    sprite.keepData = {
      resized: true,
      ylocation: 'center',
      designX: designX,
      designY: designY,
      texture: source,
      // textureOffset: source + '_offset',
      offset: 'width',
      initialWidth: GC.sidebarWidth,
    };
    this.scaler.scaleSpriteByHeight(sprite);
    this.sidebarBgSprite = sprite;

    var systemIndex = 0;
    if(this.params.systemIndex != undefined) {
      systemIndex = this.params.systemIndex;
    }
    designX = window.sidebarOffset + GC.sidebarWidth/2;
    designY = 60;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'text_sidebar_' + systemIndex
    );
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      y: 180,
      asisY: 138,
      offset: 'x',
      initialX: GC.sidebarWidth/2,
    };
    this.sidebarTextSprite = sprite;

    designX = window.sidebarOffset + GC.sidebarWidth/2;
    designY = 541.5;
    if(GC.onlyGraduate.includes(this.params.systemIndex)) {
      designY = 687.5;
    }
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_departments'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x,
      enableClick: true,
      alpha: 1,
      offset: 'x',
      initialX: GC.sidebarWidth/2,
    };
    this.btnDepartmentsSprite = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnDepartmentsSprite.alpha = 0.6;

      this.btnDepartmentsSprite.isPressed = true;
    });
    sprite.on("pointerup", pointer => {
      this.btnDepartmentsSprite.alpha = 1.0;
      
      if(this.btnDepartmentsSprite.isPressed) {
        this.toDepartments();
      }

      this.btnDepartmentsSprite.isPressed = false;
    });

    if(!GC.onlyGraduate.includes(this.params.systemIndex)) {
      designX = window.sidebarOffset + GC.sidebarWidth/2;
      designY = 687.5;
  
      var source = 'btn_change_undergraduate';
      if(this.params.categoryIndex == 1) {
        source = 'btn_change_graduate';
      }
      sprite = this.add.image(
        this.scaler.scaleX(designX),
        this.scaler.scaleY(designY),
        source
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSpriteByHeight(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY,
        x: sprite.x,
        enableClick: true,
        alpha: 1,
        offset: 'x',
        initialX: GC.sidebarWidth/2,
      };
      this.btnChangeSprite = sprite;
  
      sprite.setInteractive();
      sprite.on("pointerdown", pointer => {
        this.btnChangeSprite.alpha = 0.6;

        this.btnChangeSprite.isPressed = true;
      });
      sprite.on("pointerup", pointer => {
        this.btnChangeSprite.alpha = 1.0;

        if(this.btnChangeSprite.isPressed) {
          this.toSwitch();
        }

        this.btnChangeSprite.isPressed = false;
      });
    }

    designX = window.sidebarOffset + GC.sidebarWidth/2;
    designY = 835.5;

    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_students'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x,
      enableClick: true,
      alpha: 1,
      offset: 'x',
      initialX: GC.sidebarWidth/2,
    };
    this.btnStudentsSprite = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnStudentsSprite.alpha = 0.6;

      this.btnStudentsSprite.isPressed = true;
    });
    sprite.on("pointerup", pointer => {
      this.btnStudentsSprite.alpha = 1.0;

      if(this.btnStudentsSprite.isPressed) {
        this.toAuthors();
      }

      this.btnStudentsSprite.isPressed = false;
    });


    designX = window.sidebarOffset + GC.sidebarWidth/2;
    designY = 982.5;

    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'btn_refresh'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x,
      enableClick: true,
      alpha: 1,
      offset: 'x',
      initialX: GC.sidebarWidth/2,
    };
    this.btnRefreshSprite = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnRefreshSprite.alpha = 0.6;

      this.btnRefreshSprite.isPressed = true;
    });
    sprite.on("pointerup", pointer => {
      this.btnRefreshSprite.alpha = 1.0;

      if(this.btnRefreshSprite.isPressed) {
        window.location.reload();
      }

      this.btnRefreshSprite.isPressed = false;
    });

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    utils.disableAllButtons(this);
    if(!this.params.noAnimation) {
      this.enterAnimation(function() {
        utils.enableAllButtons(self);  
      });
    } else {
      this.cameras.main.setVisible(true);
      utils.enableAllButtons(this);
    }

    designX = 20;
    designY = 10;
    var fps = this.add.text(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      '',
      { fontFamily: 'Arial', fontSize: 20, color: '#ffffff' }
    );
    fps.setOrigin(0, 0);
    fps.keepData = {
      resized: true,
      xlocation: 'left',
      ylocation: 'top',
      designX: designX,
      designY: designY
    };
    fps.depth = 70000;
    this.scaler.scaleSpriteByHeight(fps);
    this.fps = fps;
    fps.alpha = 0;
    fps.setVisible(false);

    // designX = 0;
    // designY = 0;
    // sprite = this.add.rectangle(
    //   this.scaler.scaleX(designX),
    //   this.scaler.scaleY(designY),
    //   GC.sidebarWidth,
    //   50
    //   , 0xffffff, 0);
    // sprite.setOrigin(0, 0);
    // this.scaler.scaleSprite(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.setInteractive();

    // sprite.setInteractive();
    // sprite.on("pointerdown", pointer => {
    // });
    // sprite.on("pointerup", pointer => {
    //   // fps.alpha = 1;
    // });
  },

  update: function(){

  },

  resized: function() {
    if(this.animationing) {
      return;
    }
    utils.resized(this);

    // if(window.sidebarOffset > 0) {
    //   this.sidebarBgSprite.setTexture(this.sidebarBgSprite.keepData.textureOffset);
    // } else {
    //   this.sidebarBgSprite.setTexture(this.sidebarBgSprite.keepData.texture);
    // }
  },

  toDepartments: function() {
    var self = this;
    this.animationing = true;
    utils.disableAllButtons(this);
    this.delCookies();

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';
    this.sceneBoot.windowResized = true;
  
    this.scene.get('sceneMain').animationing = true;

    if(window.getRegion) {
      window.getRegion.abort();
    }
    
    setTimeout(() => {
      this.scene.get('sceneMain').outAnimation();
      this.scene.get('sceneLoading').outAnimation(function() {
        self.scene.get('sceneLoading').closeLoadingMask();
      });

      this.outAnimation(function() {
        self.animationing = false;
        
        window.custom.selected.systemIndex = -1;
        window.custom.selected.categoryIndex = -1;

        self.scene.stop();
      });
      self.scene.get('sceneSystemPreface').backToSystemList();
      self.scene.get('sceneBottomLine').leftInAnimation();
      self.scene.get('sceneTitle').leftInAnimation();
      // self.scene.get('sceneAnimation').categoryCamerasBack();
    }, 100);
  },

  // 本研切换
  toSwitch: function() {
    var self = this;
    this.animationing = true;
    utils.disableAllButtons(this);
    
    this.delCookies();

    window.custom.locator = null;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';
    this.sceneBoot.windowResized = true;
    
    this.scene.get('sceneMain').animationing = true;
    if(window.getRegion) {
      window.getRegion.abort();
    }

    setTimeout(() => {
      this.scene.get('sceneMain').outAnimation();
      this.scene.get('sceneLoading').outAnimation(function() {
        // self.scene.get('sceneLoading').closeLoadingMask();
      });

      this.outAnimation(function() {
        self.animationing = false;

        // self.scene.stop();
      });
      self.scene.get('sceneSystemPreface').changeCategory();
      self.scene.get('sceneBottomLine').leftInAnimation();
      self.scene.get('sceneTitle').leftInAnimation();
    }, 100);
  },

  toAuthors: function() {
    if(this.scene.get('sceneMain').viewer.current && this.scene.get('sceneMain').viewer.current.portfolio) {
      this.scene.get('sceneMain').viewer.current.portfolio.hide();
    }
    
    this.scene.get('sceneMain').viewer.students.show();

    // this.scene.get('sceneAuthors').show();
  },

  delCookies: function(){
    utils.delCookie('selected');
    utils.delCookie('locator');
  },

  enterAnimation: function(func_callback){
    this.cameras.main.setVisible(true);
    utils.sceneSlideAimation(this, 'in', function() {
      if(func_callback) {
        func_callback();
      }
    });
  },

  outAnimation: function(func) {
    var self = this;
    
    utils.sceneSlideAimation(this, 'back', function() {
      if(func) {
        func();
      }
    });
  },
});
export default SceneSideBar;