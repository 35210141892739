'use strict';

var EventEmitter = require('events').EventEmitter;

var SceneArea = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneArea ()
  {
    Phaser.Scene.call(this, { key: 'sceneArea', active: false });

    this.systemsPositions = [
      {
        id: 0,
        x: 324.5,
        y: 555,
        title: '染织服装艺术设计系',
        row: 1,
      },
      {
        id: 1,
        x: 907,
        y: 555,
        title: '环境艺术设计系',
        row: 1,
      },
      {
        id: 2,
        x: 1455.5,
        y: 555,
        title: '工艺美术系',
        row: 1,
      },
      {
        id: 3,
        x: 2024.5,
        y: 555,
        title: '基础教学研究室',
        row: 1,
      },
      {
        id: 4,
        x: 590.5,
        y: 744,
        title: '工业设计系',
        row: 2,
      },
      {
        id: 5,
        x: 1375.5,
        y: 744,
        title: '全日制科普创意与设计项目艺术硕士',
        row: 2,
      },
      {
        id: 6,
        x: 2081.5,
        y: 744,
        title: '绘画系',
        row: 2,
      },
      {
        id: 7,
        x: 330.5,
        y: 955.5,
        title: '视觉传达设计系',
        row: 3,
      },
      {
        id: 8,
        x: 848.5,
        y: 955.5,
        title: '雕塑系',
        row: 3,
      },
      {
        id: 9,
        x: 1314,
        y: 955.5,
        title: '艺术史论系',
        row: 3,
      },
      {
        id: 10,
        x: 1785,
        y: 955.5,
        title: '陶瓷艺术设计系',
        row: 3,
      },
      {
        id: 11,
        x: 2298,
        y: 955.5,
        title: '信息艺术设计系',
        row: 3,
      }

    ];
  },

  init: function(params){
    var self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.currentScene = 'sceneArea';

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.sceneBoot.windowResized = true;
    
    this.scene.bringToTop();
    this.scene.get('sceneTitle').scene.bringToTop();
    this.scene.get('sceneBottomLine').scene.bringToTop();

    this.scene.get('scenePreview').scene.bringToTop();

    this.exhibitionsLocked = [];

    for(var key in EventEmitter.prototype){
      this.__proto__[key] = EventEmitter.prototype[key];
    }

    this.scrollerBounds = {
      top: 43,
      bottom: 1080 - 43,
    };
    this.dragSprites = [];
    
    this.allowDown = false;
    this.allowUp = true;

    this.on('dragstart-systems', function () {
      if(this.animationing) {
        return
      }

      this.dragSprites.map(sprite => {
        sprite.keepData.x = sprite.x;
        sprite.keepData.y = sprite.y;
      });

      this.stopScroller();
    });
  
    this.on('drag-systems', function(evt) {
      if(this.animationing) {
        return
      }

      if(Math.abs(evt.offset) > Math.max(this.scaler.width, this.scaler.height))
        return
      // if(evt.offset < 0 && this.allowUp == false)
      //   return;
      // if(evt.offset > 0 && this.allowDown == false)
      //   return;
      let v = 1;
     
      this.dragSprites.map(sprite => {
        // console.log(sprite.keepData.x)
        sprite.x = sprite.keepData.x + evt.offset * v;
        // sprite.keepData.designX = this.scaler.getDesignX(sprite.x, sprite.keepData.xlocation);
      });
    });
  
    this.on('dragend-systems', function(evt) {
      if(this.animationing) {
        return
      }

      this.dragSprites.map(sprite => {
        // sprite.alpha = sprite.keepData.alpha;
      });

      this.startScroller(1000);
    }, this);
  
    this.on('wheel-systems', function(evt) {
      if(this.animationing) {
        return
      }

      // if(evt.offset < 0 && this.allowUp == false)
      //   return;
      // if(evt.offset > 0 && this.allowDown == false)
      //   return;
      let v = 2;
      this.dragSprites.map(sprite => {
        sprite.keepData.x = sprite.x;
        sprite.x = sprite.keepData.x - evt.offset * v;
      });
    });

    this.selectedIndex = -1;
    this.selectedPosition = null;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    // this.cameras.main.setVisible(false);

    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    var sprite = this.add.sprite(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'animation_bg'
    );
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      fill: true,
      xlocation: 'left',
      ylocation: 'center',
      designX: designX,
      designY: designY,
      // width: 2572,
      // scale: 900/sprite.height,
    };
    this.bgSprite = sprite;
    // this.bgSprite.displayWidth = this.bgSprite.keepData.width * this.scaler.vScale();
    // this.bgSprite.setCrop(0, 485, 1920, 540);

    designX = 0;
    designY = 485;
    sprite = this.add.rectangle(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      this.scaler.designRefWidth(),
      this.scaler.designRefHeight()
      , 0xffffff, 1);
    sprite.setOrigin(0.5, 1);
    this.scaler.scaleSpriteFillWidth(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      scaleFillWidth: true
    };

    designX = 0;
    designY = 485 + 540;
    sprite = this.add.rectangle(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      this.scaler.designRefWidth(),
      this.scaler.designRefHeight()
      , 0xffffff, 1);
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSpriteFillWidth(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      scaleFillWidth: true
    };

    // 最左侧
    designX = 0;
    designY = 0;
    sprite = this.add.rectangle(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      5,
      this.scaler.designRefHeight()
      , 0xffffff, 1);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFillHeight(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      ylocation: 'center',
      designX: designX,
      designY: designY,
      scaleFillHeight: true
    };
    this.leftRect = sprite;

    designX = 0;
    designY = 628;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'mask_list'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSpriteFillWidth(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY,
      scaleFillWidth: true,
    };
    this.maskListSprite = sprite;

    designX = 0;
    designY = 475;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'left_mask_2'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY
    };
    this.leftMaskSprite2 = sprite;

    this.createDragSprite();

    this.systemSprites = [];
    this.systemAndRectSprites = {
      1: [],
      2: [],
      3: [],
    };
    const rowWidth = {
      1: 0,
      2: 0,
      3: 0,
    }
    this.bottomBlocks = [];
    this.maskBlocks = [];
    for(var i = 0; i < 3; i ++) {
      for(var j = 0; j < 12; j ++) {
        (function(i, j){
          const item = {};
          var sprite;
          const position = self.systemsPositions[j];
          const row = position.row;
          let offset = 0;
          if(i != 0) {
            offset = (rowWidth[position.row]) * i;
          }
          
          designX = offset + position.x;
          designY = position.y;
  
          sprite = self.add.image(
            self.scaler.scaleBgX(designX, 'left'),
            self.scaler.scaleY(designY),
            'system_' + j
          );
          sprite.setOrigin(0.5, 0.5);
          self.scaler.scaleSprite(sprite);
          sprite.keepData = {
            resized: true,
            xlocation: 'left',
            designX: designX,
            designY: designY,
            alpha: 1,
            scale: 0.81,
            index: j,
            row: row,
            x: sprite.x,
            y: sprite.y,
            // beforeOffset: position.beforeOffset,
          };
          sprite.scale *= sprite.keepData.scale;
          sprite.depth = 10;
          const systemSprite = sprite;
          self.dragSprites.push(sprite);
          self.systemSprites.push(sprite);
          sprite.setInteractive({ draggable: true });
          sprite.on("pointerdown", pointer => {
            sprite.isPressed = true;
          });
      
          sprite.on("pointerup", pointer => {
            if(sprite.isPressed && self.dragMaxOffset < 5){
              self.selectSystem(j + 12 * i, false);
            }
            sprite.isPressed = false;
          });

          item.system = sprite;

          sprite = self.add.rectangle(
            self.scaler.scaleBgX(designX, 'left'),
            self.scaler.scaleY(designY),
            systemSprite.width * sprite.keepData.scale,
            151
            , 0xffffff, 1);
          sprite.setOrigin(0.5, 0.5);
          self.scaler.scaleSprite(sprite);
          sprite.keepData = {
            resized: true,
            xlocation: 'left',
            designX: designX,
            designY: designY,
            x: sprite.x,
            y: sprite.y,
          };
          self.dragSprites.push(sprite);
          self.bottomBlocks.push(sprite);
          sprite.visible = false;

          item.bottomBlock = sprite;

          sprite = self.add.image(
            self.scaler.scaleBgX(designX, 'left'),
            self.scaler.scaleY(designY),
            'system_block_' + j
          );
          sprite.setOrigin(0.5, 0.5);
          self.scaler.scaleSprite(sprite);
          sprite.keepData = {
            resized: true,
            xlocation: 'left',
            designX: designX,
            designY: designY,
            alpha: 1,
            scale: 0.81,
            index: j,
            row: row,
            x: sprite.x,
            y: sprite.y,
            // beforeOffset: position.beforeOffset,
          };
          sprite.scale *= sprite.keepData.scale;
          self.dragSprites.push(sprite);
          self.maskBlocks.push(sprite);
          sprite.visible = false;

          item.maskBlock = sprite;

          let nextIndex = j + 1;
          let afterPosition = self.systemsPositions[nextIndex];
          let afterOffset = 200;
          if(j !== 3 && j !== 6 && j !== 11) {
            afterOffset = afterPosition.x - self.textures.get('system_block_' + nextIndex).getSourceImage().width * 0.81 / 2 -
              (position.x + sprite.width * 0.81 / 2);
          }

          designX += systemSprite.width * systemSprite.keepData.scale/2 + afterOffset/2;
          sprite = self.add.rectangle(
            self.scaler.scaleBgX(designX, 'left'),
            self.scaler.scaleY(designY),
            afterOffset + 10,
            151
            , 0xffffff, 1);
          sprite.setOrigin(0.5, 0.5);
          self.scaler.scaleSprite(sprite);
          sprite.keepData = {
            resized: true,
            xlocation: 'left',
            designX: designX,
            designY: designY,
            // beforeOffset: 0,
            x: sprite.x,
            y: sprite.y,
          };
          self.dragSprites.push(sprite);
          sprite.setInteractive({ draggable: true });

          item.rightRect = sprite;

          self.systemAndRectSprites[row].push(item);

          if(i === 0 && j === 11) {
            for(let key in self.systemAndRectSprites) {
              const items = self.systemAndRectSprites[key];
              const startSprite = items[0].system;
              const lastSprite = items[items.length - 1].rightRect;
              let start = startSprite.keepData.designX - startSprite.width * startSprite.keepData.scale/2;
              let end = lastSprite.keepData.designX + lastSprite.width/2;
              rowWidth[key] = end - start - 2;
            }
          }
        }(i, j));
      }
    }

    designX = 0;
    designY = 0;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'empty'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY
    };
    this.selectedSystemSprite = sprite;

    this.dragSprites.forEach((sprite) => {
      sprite.setInteractive({ draggable: true });

      sprite.on('dragstart', function (pointer, dragX, dragY) {
        if(this.animationing) {
          return
        }
        // console.log(`drag start.. ${pointer.x} ${pointer.y}`);
        self.dragMaxOffset = 0;

        sprite.keepData.x = sprite.x;
        sprite.keepData.y = sprite.y;
      
        this.emit('dragstart-systems');
      }, this);
    
      sprite.on('drag', function (pointer, dragX, dragY) {
        if(this.animationing) {
          return
        }
        //console.log(dragX);
        // check whether out area
        if(self.scaler.getDirection() == 'Horizontal'){
          let offset = dragX - sprite.keepData.x;
          (Math.abs(offset) > self.dragMaxOffset) && (self.dragMaxOffset = Math.abs(offset));

          this.emit('drag-systems', {offset: offset});
        } else {
          let offset = (dragY - sprite.keepData.y);
          (Math.abs(offset) > self.dragMaxOffset) && (self.dragMaxOffset = Math.abs(offset));

          this.emit('drag-systems', {offset: offset});
        }
    
      }, this);
    
      sprite.on('dragend', function (pointer, dragX, dragY) {
        if(this.animationing) {
          return
        }
        // console.log('drag end');
        this.emit('dragend-systems');
      }, this);
    
      sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
        if(this.animationing) {
          return
        }
        this.emit('wheel-systems', {offset: deltaY * 0.4});
      }, this);  
    });

    designX = 0;
    designY = 483;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'mask_left'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY,
      height: this.scaler.designRefHeight() - 26
    };
    sprite.visible = false;
    sprite.depth = 10;
    sprite.displayHeight = sprite.keepData.height * this.scaler.vScale();
    this.maskLeftSprite = sprite;

    designX = 134;
    designY = 674;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'btn_back');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    sprite.depth = 20;
    sprite.visible = false;
    this.btnBackSprite = sprite;

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      this.btnBackSprite.setAlpha(0.5);
    });

    sprite.on("pointerup", pointer => {
      this.btnBackSprite.setAlpha(1.0);

      this.back();
    });

    this.input.on('pointerup', function () {
      if(!this.inputDisable) {
        utils.updateButtonsAlpha(this);
      }
    }, this);

    utils.disableAllButtons(this);

    // if(this.scene.isActive('scenePreface')){
    //   this.scene.get('scenePreface').btnBackSprite.visible = false;
    // }

    if(self.params.autoJump === 'personal' || self.params.autoJump === 'system') {
      this.maskLeftSprite.visible = true;
      this.leftMaskSprite2.visible = false
      // this.scene.get('sceneBottomLine').changeWidth();
      self.selectSystem(self.params.systemIndex, true);
    } else {
      // this.scene.get('sceneBottomLine').changeWidthAnimation();
      this.enterAnimation(function() {
        self.maskLeftSprite.visible = true;
        self.btnBackSprite.visible = true;
        utils.fadeInAnimation(self, [self.maskLeftSprite, self.btnBackSprite], 300);
        
        self.time.delayedCall(400, function() {
          utils.enableAllButtons(self);
          self.animationing = false;
          self.resized();

          self.leftMaskSprite2.visible = false;
          
          self.startScroller(3000);
        }, this);
      });
    }
  },

  update: function() {
    if(this.animationing) {
      return;
    }
    this.checkLoopScroller();
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
    
    this.resetPosition();
  },

  hide: function() {
    this.cameras.main.setVisible(false);
  },

  show: function() {
    this.cameras.main.setVisible(true);
  },

  createDragSprite: function () {
    const self = this;
    let designX = 0;
    let designY = 370;
    let sprite = this.add.rectangle(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      1920,
      712,
      0xff0000,
      0);
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSpriteFillWidth(sprite);
    sprite.keepData = { 
      resized: true,
      designX: designX,
      designY: designY,
      xlocation: 'center',
      scaleFillWidth: true,
      x: sprite.x,
      y: sprite.y,
    };

    sprite.setInteractive({ draggable: true });

    sprite.on('dragstart', function (pointer, dragX, dragY) {
      if(this.animationing) {
        return
      }

      // console.log(`drag start.. ${pointer.x} ${pointer.y}`);
      sprite.keepData.x = sprite.x;
      sprite.keepData.y = sprite.y;

      this.emit('dragstart-systems');
    }, this);
  
    sprite.on('drag', function (pointer, dragX, dragY) {
      if(this.animationing) {
        return
      }

      //console.log(dragX);
      // check whether out area
      if(self.scaler.getDirection() == 'Horizontal'){
        this.emit('drag-systems', {offset: dragX - sprite.keepData.x});
      } else{
        this.emit('drag-systems', {offset: (dragY - sprite.keepData.y)});
      }
  
    }, this);
  
    sprite.on('dragend', function (pointer, dragX, dragY) {
      if(this.animationing) {
        return
      }

      // console.log('drag end');
      this.emit('dragend-systems');
    }, this);
  
    sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
      if(this.animationing) {
        return
      }
      this.emit('wheel-systems', {offset: deltaY * 0.4});
    }, this);
  },

  selectSystem: function(index, noAnimation) {
    this.animationing = true;
    const self = this;
    const systemIndex = index;
    utils.disableAllButtons(this);
    this.stopScroller();

    window.custom.selected.systemIndex = systemIndex % 12;

    this.selectedIndex = index;
    this.selectedPosition = {
      x: self.systemSprites[index].x,
      y: self.systemSprites[index].y,
    }

    if(noAnimation) {
      self.scene.launch('sceneSystemPreface', {
        systemIndex: systemIndex % 12,
        systemPosition: {
          x: self.systemSprites[index].x,
          y: self.systemSprites[index].y,
          scale: self.systemSprites[index].scale
        },
        ...noAnimation ? self.params : {},
      })
    } else {
      // 1.未选择的系渐隐    
      this.bottomBlocks.forEach((sprite, i) => {
        if(i !== index) {
          sprite.visible = true;
        }
      });
      this.maskBlocks.forEach(sprite => {
        sprite.visible = true;
      });
  
      utils.fadeInAnimation(this, this.bottomBlocks, GC.changePageDuration/2).then(() => {});
      
      const hideSprites = [];
      this.systemSprites.forEach((s, i) => {
        if(i != index) {
          hideSprites.push(s);
        }
      });
      utils.fadeOutAnimation(this, hideSprites, GC.changePageDuration/2).then(() => {
        // utils.hide(this);
        
        // 2.选择的系向上移动、变大
        self.scene.launch('sceneSystemPreface', {
          systemIndex: systemIndex % 12,
          systemPosition: {
            x: self.systemSprites[index].x,
            y: self.systemSprites[index].y,
            scale: self.systemSprites[index].scale
          },
          ...noAnimation ? self.params : {},
        })
      });
    }
  },

  back: function(){
    const self = this;
    utils.disableAllButtons(this);

    this.scene.start('sceneEnter');
    this.scene.get('sceneTitle').hide();
    this.scene.get('sceneBottomLine').fadeOutAnimation(function() {
      // self.scene.get('sceneBottomLine').widthRecover();
    });
  },

  checkLoopScroller: function() { 
    for(let key in this.systemAndRectSprites) {
      const items = this.systemAndRectSprites[key];

      const startSprite = items[0].system;
      const lastSprite = items[items.length - 1].rightRect;
      let start = startSprite.keepData.designX - startSprite.width/2;
      let end = lastSprite.keepData.designX + lastSprite.width/2;

      let totalWidth = end - start;

      const left = this.scaler.scaleBgX(-totalWidth/2, 'center');
      const right = this.scaler.scaleBgX(totalWidth/2, 'center');

      var minIndex = 0;
      var maxIndex = 0;
      var min = items[minIndex].system.x;
      var max = items[maxIndex].rightRect.x;

      items.forEach((item, index) => {
        if(item.system.x < min) {
          minIndex = index;
          min = item.system.x;
        }

        if(item.system.x > max) {
          maxIndex = index;
          max = item.system.x;
        }
      });

      const offset = 5;
      if(items[minIndex].system.x < left) {
        const item = items[minIndex];
        const systemSprite = item.system;
        const bottomBlockSprite = item.bottomBlock;
        const maskBlockSprite = item.maskBlock;
        const rightRectSprite = item.rightRect;
        
        const maxSprite = items[maxIndex].rightRect;
        systemSprite.x = maxSprite.x + systemSprite.displayWidth/2 + maxSprite.displayWidth/2 - offset;
        bottomBlockSprite.x = systemSprite.x;
        maskBlockSprite.x = systemSprite.x;
        rightRectSprite.x = systemSprite.x + systemSprite.displayWidth/2 + rightRectSprite.displayWidth/2 - offset;
      }

      if(items[maxIndex].system.x - items[maxIndex].system.displayWidth > right) {
        const item = items[maxIndex];
        const systemSprite = item.system;
        const bottomBlockSprite = item.bottomBlock;
        const maskBlockSprite = item.maskBlock;
        const rightRectSprite = item.rightRect;
        
        const minSprite = items[minIndex].system;

        systemSprite.x = minSprite.x - minSprite.displayWidth/2 - systemSprite.displayWidth/2 - (rightRectSprite.displayWidth - offset) + offset;
        bottomBlockSprite.x = systemSprite.x;
        maskBlockSprite.x = systemSprite.x;
        rightRectSprite.x = systemSprite.x + systemSprite.displayWidth/2 + rightRectSprite.displayWidth/2 - offset;
      }
    }
  },

  enterAnimation: function(func) {
    const self = this;
    this.animationing = true;

    const slideInTargets = this.dragSprites.concat([this.bgSprite, this.leftRect, this.maskListSprite, this.leftMaskSprite2]);
    utils.slideAimation(this, slideInTargets, 'in', GC.changePageDuration, function() {
        if(func) {
          func();
        }
    });
  },

  backAnimation: function() {
    const self = this;
    this.resetPosition();

    utils.show(this);

    this.bottomBlocks.forEach((sprite, i) => {
      sprite.visible = false;
    });
    this.maskBlocks.forEach(sprite => {
      sprite.visible = false;
    });

    const targets = this.systemSprites.concat(this.bgSprite)
    targets.forEach(s => {
      s.alpha = 1;
    });

    self.startScroller(100);

    utils.enableAllButtons(self);
    this.showBtnBack();
    this.animationing = false;
    this.resized();
    this.scene.get('sceneTitle').resized();
  }, 
  
  autoScroller: function() {
    if(this.autoScrollerTimer) {
      this.autoScrollerTimer.destroy();
    }
    this.autoScrollerTimer = this.time.addEvent({
      delay: 1000/25,
      callback: function(){
        this.dragSprites.forEach(sprite => {
          sprite.x -= this.scaler.vScale() * 1.4;

          // sprite.keepData.designX = this.scaler.getDesignX(sprite.x, sprite.keepData.xlocation);
        });
        // this.scene.get('sceneBottomLine').scroll(this.scaler.vScale() * 1.4);
        // this.bgSpriteScroll(this.scaler.vScale() * 1.4);
      },
      callbackScope: this,
      loop: true
    });

  },

  startScroller: function(delay) {
    const self = this;
    
    this.stopScroller();

    this.reScrollerTimer = this.time.delayedCall(delay, function() {
      self.autoScroller();
    }, this);
  },

  stopScroller: function() {
    if(this.reScrollerTimer) {
      this.reScrollerTimer.destroy();
    }
    if(this.autoScrollerTimer) {
      this.autoScrollerTimer.destroy();
    }
  },

  slideOutAnimation: function() {
    this.animationing = true;
    utils.slideAimation(this, this.children.list, 'out').then(() => {
      this.animationing = false;
      this.cameraReset();
    });
  },

  leftInAnimation: function() {
    this.show();
    this.animationing = true;
    utils.slideAimation(this, this.children.list, 'leftIn').then(() => {
      this.animationing = false;
    })
  },

  showBtnBack: function() {
    this.btnBackSprite.setVisible(true);
  },

  hideBtnBack: function() {
    this.btnBackSprite.setVisible(false);
  },

  cameraReset: function() {
    this.cameras.main.x = 0;
    this.cameras.main.y = 0;
    this.cameras.main.setVisible(false);
  },

  resetPosition: function() {
    if(this.selectedIndex != -1 && this.selectedPosition != null) {
      const offset = this.selectedPosition.x - this.systemSprites[this.selectedIndex].x
      this.dragSprites.forEach(sprite => {
        sprite.x += offset;
      });
    }

    if(this.bgSpriteOffset) {
      this.bgSprite.x = this.bgSpriteOffset + this.scaler.scaleX(this.bgSprite.keepData.designX);
    }
  },

  bgSpriteScroll: function(step) {
    if(this.bgSprite.x > this.scaler.scaleX(- (2572 - 1920))) {
      this.bgSprite.x -= step;
    } 
    
    if(this.bgSprite.x < this.scaler.scaleX(- (2572 - 1920))) {
      this.bgSprite.x = this.scaler.scaleX(- (2572 - 1920))
    }

    this.bgSpriteOffset = this.bgSprite.x - this.scaler.scaleX(this.bgSprite.keepData.designX);
  },

  updateFrame: function() {
    this.bgSprite.setTexture('animation_bg_' + animationFrame);
  },
});
export default SceneArea;