'use strict';

import scaler from "./scaler";
import GC from "./const";

import emptyImg from '../images/empty.png';
import textLoadingBlueImg from '../images/text_loading_blue.png';
import textLoadingPinkImg from '../images/text_loading_pink.png';
// import iconLoadingImg from '../images/icon_loading.png';

import loadingFontBlueImg from '../images/loading_font_blue.png';
import loadingFontPinkImg from '../images/loading_font_pink.png';
import loadingFontXml from '../images/loading_font.xml';
import btnRefreshBlueImg from '../images/btn_refresh_blue.png';
import btnRefreshPinkImg from '../images/btn_refresh_pink.png';
// import ballBlueImg from '../images/ball_blue.png';
// import ballPinkImg from '../images/ball_pink.png';


var SceneBoot = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneBoot ()
  {
    Phaser.Scene.call(this, { key: 'sceneBoot', active: true });

    this.currentScene = 'sceneBoot';
  },

  init: function(params){
    this.scaler = scaler;
    this.windowResized = false;
    this.bgSprite;
    this.textMobile;
 
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;
    this.recalculateSize2();

    this.sceneToResize = [
      this.scene.get('sceneLoading'),
      this.scene.get('scenePreview'),
      this.scene.get('sceneEnter'),
      this.scene.get('sceneCountdown'),
      this.scene.get('sceneTitle'),
      this.scene.get('sceneBottomLine'),
      this.scene.get('scenePreface'),
      this.scene.get('sceneSystemPreface'),
      this.scene.get('sceneCategory'),
      this.scene.get('sceneArea'),
      this.scene.get('sceneMain'),
      this.scene.get('sceneSideBar'),
    ];
    

    this.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.screenIsRightDirection = true;

    //竖屏
    this._designRefWidth = 1080;
    this._designRefHeight = 1920;

    this.getRegionDate = false;
  },

  preload: function ()
  {
    this.load.image('empty', emptyImg);
    this.load.image('text_loading_blue', textLoadingBlueImg);
    this.load.image('text_loading_pink', textLoadingPinkImg);
    this.load.bitmapFont('loading_font_blue', loadingFontBlueImg, loadingFontXml);
    this.load.bitmapFont('loading_font_pink', loadingFontPinkImg, loadingFontXml);
    this.load.image('btn_refresh_blue', btnRefreshBlueImg);
    this.load.image('btn_refresh_pink', btnRefreshPinkImg);
    // this.load.image('ball_blue', ballBlueImg);
    // this.load.image('ball_pink', ballPinkImg);
  },

  create: function ()
  {
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.rectangle(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      1,
      1,
      0xffffff,
      1);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = { 
      resized: true,
      designX: designX,
      designY: designY,
      xlocation: 'center',
      ylocation: 'center',
      fill: true,
    };
    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   'bg_preface'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSpriteByMax(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   scaleByMax: true,
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.initialScale = sprite.scale;
    // this.bgSprite = sprite;

    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   'icon_2020_color'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSpriteFill(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   fill: true,
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.initialScale = sprite.scale;
    // this.icon2020ColorSprite = sprite;

    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   'mask'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSpriteFill(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   fill: true,
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.initialScale = sprite.scale;
    // this.maskSprite = sprite;

    this.windowResized = true;

    this.scale.on('resize',function resize(gameSize, baseSize, displaySize) {
      // if(this.scene.isActive('sceneEnter')){
      //   this.recalculateSize2();
      // } else{
      //   this.recalculateSize();
      // }
      if(this.scaleMode == 'full'){
        this.recalculateSize2();
      } else{
        this.recalculateSize();
      }
      
      this.windowResized = true;
    }, this);    

    //test
    // this.scene.launch('scenePreload');

    // this.bgAnimation();
  },

  update: function(){
    if (this.windowResized) {
      this.scale.resize(this.width, this.height);
      this.resized();
      this.windowResized = false;
    }

    // this.colourBarSprite.tilePositionX += this.scaler.vScale()*0.7;

    if(this.getRegionDate == false && JSON.stringify(window.custom.systemList) !== '{}'){
      this.getRegionDate = true;
      var rid = utils.getUrlParam('rid');
      var locator = utils.getUrlParam('locator');

      var sid = utils.getUrlParam('sid');
      this.toAnalysisParams(rid, locator, sid);
    }
  },

  recalculateSize2: function() {
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;
  
    // // resize the canvas
    let canvas = document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.width = this.width;
      canvas.height = this.height;
    }
  
    let designRefWidth = GC.resolution * this.width/this.height;
    // let designRefHeight = GC.resolution;
  
    if(this.width >= this.height){
      this.scaler.config(this.width, this.height, 1920, 1080);
    } else{
      this.scaler.config(this.width, this.height, 1080, 1920);
    }
    
  },

  recalculateSize: function() {
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;

    // // resize the canvas
    let canvas = document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.width = this.width;
      canvas.height = this.height;
    }

    let designRefWidth = GC.resolution * this.width/this.height;
    let designRefHeight = GC.resolution;

    this.scaler.config(this.width, this.height, designRefWidth, designRefHeight);
  },

  resized: function () {
    if(this.scaler.width >= this.scaler.height) {
      if(window.needOffset) {
        window.sidebarOffset = 70;
      }

      this.cameras.main.rotation = 0;
      this.cameras.main.centerOnX(this.scaler.width/2);
      this.cameras.main.centerOnY(this.scaler.height/2);

      this.sceneToResize.forEach(scene => {
        if(scene && scene.cameras.main){
          scene.cameras.main.rotation = 0;
          scene.cameras.main.centerOnX(this.scaler.width/2);
          scene.cameras.main.centerOnY(this.scaler.height/2);

          scene.cameras.main.setViewport(scene.cameras.main.x, scene.cameras.main.y, this.scaler.width, this.scaler.height);
        }
      });
    } else{
      window.sidebarOffset = 0;

      this.cameras.main.rotation = 3.14/2;
      this.cameras.main.centerOnX(this.scaler.height/2);
      this.cameras.main.centerOnY(this.scaler.width/2);

      this.sceneToResize.forEach(scene => {
        if(scene && scene.cameras.main){
          scene.cameras.main.rotation = 3.14/2;
          scene.cameras.main.centerOnX(this.scaler.height/2);
          scene.cameras.main.centerOnY(this.scaler.width/2);

          scene.cameras.main.setViewport( scene.cameras.main.x, scene.cameras.main.y, this.scaler.width, this.scaler.height);
        }
      }); 
    }

    utils.resized(this);

    this.sceneToResize.forEach(scene => {
      if(scene && scene.resized && scene.scene.isActive()){
        scene.resized();
      }
    });
  },

  getUrlParam: function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },

  toAnalysisParams: function(rid, locator, sid) {
    var self = this;
    if(rid && locator){//个人展厅
      var latest = '';
      var label = '';
      if(window.custom.systemList[rid] && window.custom.systemList[rid].latest){
        latest = window.custom.systemList[rid].latest;
        label = window.custom.systemList[rid].label;
      }

      if(latest == ''){
        self.toNextPage();
        return;
      }

      var systemIndex = GC.systemNames.indexOf(label.split('-')[1]);
      var categoryIndex = ['本科生', '研究生'].indexOf(label.split('-')[2]);
      var locator = locator;

      //get region data
      $.ajax({
        url: latest,
        type: "GET",
        async: true,
        success: function(data) {
          console.log('GET IMAGES SUCCESS!');
          let regionsRaw = data.regions;
          window.publicPath = data.publicPath;
          window.theme = data.theme;
  
          var texture;
          var index;
          for(var i = 0; i < regionsRaw.length; i ++){
            if(regionsRaw[i].meta.locator == locator){
              index = i;
              texture = regionsRaw[i].meta.name.texture;
            }
          }
  
          if(texture && index != undefined){
            var personal = {
              texture: texture,
              path: data.publicPath + regionsRaw[index].preloadMaterials.images[texture]
            };
            self.toNextPage({
              personal: personal,
              latest: latest,
              systemIndex: systemIndex,
              categoryIndex: categoryIndex,
              locator: locator,
            });
          } else{
            //
            self.toNextPage();
          }
          
        }, error : function(response) {
          console.log("GET IMAGES FAILED!");
          console.log(JSON.stringify(response));
  
          self.toNextPage();
        }
      });
    } else if(sid){//系长廊
      var latest = '';
      var label = '';
      var systemIndex = GC.systemMap[sid];
      self.toNextPage({system: true, systemIndex: systemIndex});
    } else{//正常模式
      this.toNextPage();
    }
    
  },

  toNextPage: function(params){
    if(params){
      this.scene.launch('scenePreload', params);
    } else{
      this.scene.launch('scenePreload');
    }
  },

  bgAnimation: function(){
    this.tweens.add({
      targets: this.bgSprite,
      scale: this.bgSprite.initialScale * 1.2,
      duration: 3000,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
        
      },
    }); 

    this.tweens.add({
      targets: this.icon2020ColorSprite,
      scale: this.icon2020ColorSprite.initialScale * 1.1,
      duration: 3000,
      delay: 1000,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
        
      },
    }); 
  },

  hideLogo: function(){
    this.logoSprite.setVisible(false);
  },

  showLogo: function(){
    this.logoSprite.setVisible(true);
  },
});
export default SceneBoot;