'use strict';

var SceneEnter = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneEnter ()
  {
    Phaser.Scene.call(this, { key: 'sceneEnter', active: false });
  },

  init: function(params){
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.currentScene = 'sceneEnter';

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();
    this.scene.get('scenePreview').scene.bringToTop();

    this.sceneBoot.windowResized = true;

    this.clickedEnterBtn = false;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    this.homeGroup = this.createHomeGroup();

    /**正式 -- start */
    this.enterActionGroup;
    
    if(this.params && this.params.personal) { // 个人展位
      this.enterActionGroup = this.createBtnEnterPersonalGroup();
    } else if(this.params && this.params.system) { // 系展位
      this.enterActionGroup = this.createBtnEnterSystemGroup();
    } else {
      this.enterActionGroup = this.createBtnEnterGroup();
    }
    
    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    this.sceneBoot.windowResized = true;

    if(this.params && this.params.personal){
      //个人展位
      self.showBtnAnimation();
    } else if(this.params && this.params.system){
      //系长廊
      self.showBtnAnimation();
    }else{
      //正常模式
      self.getCookie();
    }
    /**正式 -- end */

    /**6月9号上线 敬请期待 -- start */
    // this.createTimeGroup()
    /**6月9号上线 敬请期待 -- end */


    if(!bgImageLoaded) {
      this.scene.get('scenePreload').loadAnimationImages();
    }

    this.scene.get('sceneLoading').closeLoadingMask();
    this.scene.get('sceneLoading').moveRight();
  },

  update: function(){
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  createHomeGroup: function(){
    var self = this;
    var designX, designY, sprite;

    var group = this.add.group();

    this.startSprites = [];
    for(let i = 0; i < 4; i ++) {
      designX = -812 + (800-1) * (i%2);
      designY = (284-1)*parseInt(i/2);
      var sprite = this.add.sprite(
        this.scaler.scaleBgX(designX, 'center'),
        this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
        'animation_bg'
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        xlocation: 'center',
        ylocation: 'center',
        designX: designX,
        designY: designY,
        scale: 1080/sprite.height,
      };
      if(i === 1) {
        sprite.keepData.transitionAnimation = true;
      }
      sprite.scale *= sprite.keepData.scale;
      sprite.setCrop(972/sprite.keepData.scale, 174/sprite.keepData.scale, 800 /sprite.keepData.scale, 284 / sprite.keepData.scale);
      group.add(sprite);

      this.startSprites.push(sprite);
    }

    designX = 87;
    designY = 97;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'text_start_left'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      ylocation: 'top',
      locationByBg: true,
      designX: designX,
      designY: designY,
      offset: 'x',
      initialX: designX,
    };
    group.add(sprite);

    designX = -92;
    designY = 95;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'text_start_right');
    sprite.setOrigin(1, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      ylocation: 'top',
      designX: designX,
      designY: designY
    };
    group.add(sprite);

    designX = 62;
    designY = -103;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'copyright_left');
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      ylocation: 'bottom',
      designX: designX,
      designY: designY,
      offset: 'x',
      initialX: designX,
    };
    group.add(sprite);

    designX = -67;
    designY = -99;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'copyright_right');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      ylocation: 'bottom',
      designX: designX,
      designY: designY
    };
    group.add(sprite);

    return group;
  },

  createTimeGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = -89;
    designY = 845.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'time');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
    };
    group.add(sprite);

    return group;
  },

  createBtnEnterGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = -428;
    designY = 836.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'icon_enter');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      alpha: 1,
      enableClick: true,
    };
    group.add(sprite);
    const iconSprite = sprite;

    designX = -305;
    designY = 840;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_enter');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      alpha: 1,
      enableClick: true,
    };
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      sprite.setAlpha(0.5);
      iconSprite.setAlpha(0.5);
      sprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      sprite.setAlpha(1.0);
      iconSprite.setAlpha(1.0);
      if(sprite.isPressed) {
        this.clickEnterButton();
      }
      sprite.isPressed = false;
    });

    return group;
  },

  createBtnEnterPersonalGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    let nameSprite, iconSprite;
    designX = -88;
    designY = 846;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      this.params.personal.texture);
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    group.add(sprite);
    nameSprite = sprite;

    designX = -88 - nameSprite.width - 42;
    designY = 846;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'icon_enter');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      alpha: 1,
      enableClick: true,
    };
    group.add(sprite);
    iconSprite = sprite;

    const width = nameSprite.width + 42 + iconSprite.width;
    designX = -88;
    designY = 846;
    sprite = this.add.rectangle(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      width,
      140
      , 0xffffff, 0);
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      nameSprite.setAlpha(0.5);
      iconSprite.setAlpha(0.5);
      nameSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      nameSprite.setAlpha(1.0);
      iconSprite.setAlpha(1.0);

      if(nameSprite.isPressed) {
        this.clickPersonalEnterButton();
      }
      nameSprite.isPressed = false;
    });
    

    return group;
  },

  createBtnEnterSystemGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    let iconSprite, nameSprite;

    designX = -93;
    designY = 846;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'system_name');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    nameSprite = sprite;
    group.add(sprite);

    designX = -93 - nameSprite.width - 42;
    designY = 846;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'icon_enter');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      alpha: 1,
      enableClick: true,
    };
    group.add(sprite);
    iconSprite = sprite;

    const width = nameSprite.width + 42 + iconSprite.width;
    designX = -93;
    designY = 846;
    sprite = this.add.rectangle(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      width,
      140
      , 0xffffff, 0);
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      nameSprite.setAlpha(0.5);
      iconSprite.setAlpha(0.5);
      nameSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      nameSprite.setAlpha(1.0);
      iconSprite.setAlpha(1.0);

      if(nameSprite.isPressed) {
        this.clickSystemEnterButton();
      }
      nameSprite.isPressed = false;
    });
    
    return group;
  },

  clickEnterButton: function() {
    const self = this;
    utils.disableAllButtons(this);

    const targets = [];
    this.children.list.forEach(s => {
      if(s.keepData && !s.keepData.transitionAnimation) {
        targets.push(s);
      }
    });

    
    utils.fadeOutAnimation(this, targets, GC.changePageDuration/2).then(() => {
      self.scene.start('scenePreface');
    });
    

    this.clickedEnterBtn = true;
  },

  clickPersonalEnterButton: function() {
    // if(this.circleAnimationTimer) {
    //   this.circleAnimationTimer.destroy();
    // }
    window.custom.locator = this.params.locator;
    
    utils.disableAllButtons(this);

    this.scene.start(
      'sceneArea',
      {
        autoJump: 'personal',
        systemIndex: this.params.systemIndex,
        categoryIndex: this.params.categoryIndex,
      }
    );
  },

  clickSystemEnterButton: function() {
    utils.disableAllButtons(this);

    // this.scene.get('sceneVideo').scene.stop();

    this.scene.get('sceneTitle').scene.setVisible(true);
    this.scene.get('sceneTitle').cameras.main.alpha = 1;
    this.scene.get('sceneTitle').enterAnimation();
    
    this.scene.start(
      'sceneArea',
      {
        autoJump: 'system',
        systemIndex: this.params.systemIndex
      }
    );
  },

  getCookie: function(){
    var self = this;
    var selected = null;
    try {
      selected = JSON.parse(decodeURIComponent(utils.getCookie('selected')));
    }
    catch(err) {
      console.log(err);
    }
  
    window.custom.locator = utils.getCookie('locator');

    if(
      selected == null ||
      !(selected.systemIndex >= 0 && selected.systemIndex <= 11) ||
      !(selected.categoryIndex >= 0 && selected.categoryIndex <= 1)
    ){
      self.showBtnAnimation();
      return;
    }
  
    window.custom.selected = selected;
  
    window.custom.noTip = true;

    this.scene.start('sceneArea', {
      autoJump: 'personal',
      systemIndex: selected.systemIndex,
      categoryIndex: selected.categoryIndex
    });
  },

  // startAnimation0: function() {
  //   if(!bgImageLoaded) {
  //     return;
  //   }
  //   this.ballAnimationStarted = true;
  //   let imageNumber = 70;
  //   let frameConfig = {
  //     start: 0, end: 3
  //   }

  //   this.anims.create({
  //     key: 'start',
  //     frames: this.anims.generateFrameNames('start_0', frameConfig),
  //     frameRate: 25,
  //     repeat: -1
  //   });

  //   const start = this.anims.get('start');
  //   for(var i = 1; i < imageNumber; i ++) {
  //     if(i == imageNumber - 1) {
  //       frameConfig = {
  //         start: 0, end: 0
  //       } 
  //     }
  //     const newFrames = this.anims.generateFrameNames('start_' + i, frameConfig);
  //     start.addFrame(newFrames);
  //   }
    
  //   this.startSprite.play('start');
  //   // return;
  //   // var frame = 0;
  //   // var totalFrame = 401;
  //   // var frames = 12;
  //   // this.startSprite.setTexture('start_' + parseInt(frame/frames));
  //   // this.circleAnimationTimer = this.time.addEvent({
  //   //   delay: 1000/25,
  //   //   callback: function(){
  //   //     frame ++;
        
  //   //     if(frame > totalFrame - 1){
  //   //       frame = 0;
  //   //     }
        
  //   //     if(frame%frames == 0) {
  //   //       this.startSprite.setTexture('start_' + parseInt(frame/frames));
  //   //     }
        
  //   //     this.startSprite.setFrame(frame%frames);
  //   //   },
  //   //   callbackScope: this,
  //   //   loop: true
  //   // });
  // },

  // startAnimation: function() {
  //   if(!bgImageLoaded) {
  //     return;
  //   }
  //   this.ballAnimationStarted = true;

  //   var frame = 0;
  //   var totalFrame = 70;
  //   var frames = 1;
  //   var step = 1;

  //   this.startSprites.forEach(sprite => {
  //     sprite.setTexture('animation_bg_' + parseInt(frame/frames));
  //   })
    
  //   this.circleAnimationTimer = this.time.addEvent({
  //     delay: 1000/25,
  //     callback: function(){
  //       frame += step;
        
  //       if(frame > totalFrame - 1){
  //         // frame = 0;

  //         step = -step;
  //         frame += step;
  //       }

  //       if(frame < 0){
  //         step = -step;
  //         frame += step;
  //       }
        
  //       if(frame%frames == 0) {
  //         this.startSprites.forEach(sprite => {
  //           sprite.setTexture('animation_bg_' + parseInt(frame/frames));
  //         })
  //       }
        
  //       // this.startSprites.forEach(sprite => {
  //       //   sprite.setFrame(frame%frames);
  //       // })
  //     },
  //     callbackScope: this,
  //     loop: true
  //   });
    
  // },
  
  showBtnAnimation: function(){
    var self = this;
    this.enterActionGroup.setVisible(true);
    this.enterActionGroup.getChildren().forEach((s, index) => {
      this.tweens.add({
        targets: s,
        alpha: {
          start: 0,
          to: s.keepData.alpha
        },
        duration: 300,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        yoyo: false,
        onComplete: function () {
        },
      }); 
    });
  },

  enterAnimation: function(func) {
    var self = this;
    var duration = 500;//GC.animationDuration;
    this.tweens.add({
      targets: this.cameras.main,
      alpha: {
        start: 0.2,
        to: 1
      },
      duration: duration,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        if(func) {
          func();
        }
      },
    }); 
  },

  outAnimation: function() {
    var self = this;
    var duration = 1800;
    this.tweens.add({
      targets: this.cameras.main,
      alpha: 0,
      duration: duration,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        // self.scene.stop();
      },
    }); 
  },

  updateFrame: function() {
    this.startSprites.forEach(sprite => {
      sprite.setTexture('animation_bg_' + animationFrame);
    })
  },
});
export default SceneEnter;