'use strict';
import Scroller from "./scroller";

var SceneSystemPreface = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneSystemPreface ()
  {
    Phaser.Scene.call(this, { key: 'sceneSystemPreface', active: false });

    this.scrollerBounds = {
      top: 466,
      bottom: 946,
    }

    // 系主任寄语文字需要滚动的系
    this.scrollerSystemIndex = [0];
  },

  init: function(params){
    var self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.currentScene = 'sceneSystemPreface';

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.sceneBoot.windowResized = true;

    this.scene.bringToTop();

    this.scene.get('sceneTitle').scene.bringToTop();
    this.scene.get('scenePreview').scene.bringToTop();
    this.scene.get('sceneBottomLine').scene.bringToTop();
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    this.cameras.main.setVisible(false);

    designX = 0;
    designY = 0;
    var sprite = this.add.sprite(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'animation_bg'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      fill: true,
      xlocation: 'center',
      ylocation: 'center',
      designX: designX,
      designY: designY,
      // scale: 900/sprite.height,
    };
    this.bgSprite = sprite;

    if(this.textures.getTextureKeys().includes('animation_bg_' + animationFrame)) {
      this.bgSprite.setTexture('animation_bg_' + animationFrame);
    }

    const key ='system_' + this.params.systemIndex;
    designX = -365 + this.textures.get(key).getSourceImage().width/2;
    designY = 318;
    this.maskGroup = utils.createMaskGroup(this, key, designX, designY, 'center', 'left');

    if(!GC.onlyGraduate.includes(this.params.systemIndex)) {
      designX = 0;
      designY = 0;
      var sprite = this.add.sprite(
        this.scaler.scaleBgX(designX, 'right'),
        this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
        // 58,
        // 234,
        'animation_bg'
      );
      sprite.setOrigin(1, 0.5);
      this.scaler.scaleSprite(sprite);
      const scale = 1080/sprite.height
      sprite.keepData = {
        resized: true,
        // fill: true,
        xlocation: 'right',
        ylocation: 'center',
        designX: designX,
        designY: designY,
        scale: scale,
      };
      sprite.scale *= scale;
      sprite.setVisible(false);
      this.undergraduateBlockSprite = sprite;

      if(this.textures.getTextureKeys().includes('animation_bg_' + animationFrame)) {
        this.undergraduateBlockSprite.setTexture('animation_bg_' + animationFrame);
      }

      this.undergraduateBlockSprite.setCrop((1920 - 58)/scale, (571 - 234/2)/scale, 58/scale, 234/scale)

      // this.undergraduateBlockSprite.tilePositionX = 1920 - 58;
      // this.undergraduateBlockSprite.tilePositionY = 572 - 234/2;
    }

    designX = 0;
    designY = 0;
    var sprite = this.add.sprite(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'animation_bg'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    const scale = 1080/sprite.height
    sprite.keepData = {
      resized: true,
      // fill: true,
      xlocation: 'right',
      ylocation: 'center',
      designX: designX,
      designY: designY,
      scale: scale,
    };
    sprite.scale *= scale;
    sprite.setVisible(false);
    this.graduateBlockSprite = sprite;

    if(this.textures.getTextureKeys().includes('animation_bg_' + animationFrame)) {
      this.graduateBlockSprite.setTexture('animation_bg_' + animationFrame);
    }

    // this.graduateBlockSprite.tilePositionX = 1920 - 58;
    // this.graduateBlockSprite.tilePositionY = 869 - 234/2;

    this.graduateBlockSprite.setCrop((1920 - 58)/scale, (877 - 234/2)/scale, 58/scale, 234/scale)


    designX = -892;
    designY = 906;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'system_preface_name_' + this.params.systemIndex
    );
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
    };
    this.prefaceNameSprite = sprite;

    this.scroller = new Scroller(this, this.scaler, 'Vertical');
    this.dragBg = this.scroller.create();

    designX = -357;
    designY = 466;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'system_preface_' + this.params.systemIndex
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
    };
    this.prefaceSprite = sprite;

    if(this.scrollerSystemIndex.includes(this.params.systemIndex)) {
      this.scroller.bgSprite = this.prefaceSprite;
      this.scroller.setDragSprites([this.prefaceSprite]);
      this.scroller.setScrollerBounds(this.scrollerBounds);
    }

    designX = -357;
    designY = 464;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'mask_top'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      width: this.prefaceSprite.width
    };
    sprite.alpha = 0;
    sprite.displayWidth = sprite.keepData.width * this.scaler.vScale();
    this.maskTopSprite = sprite;

    designX = -357;
    designY = 948;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'mask_bottom'
    );
    sprite.setOrigin(0, 1);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      width: this.prefaceSprite.width
    };
    sprite.alpha = 0;
    sprite.displayWidth = sprite.keepData.width * this.scaler.vScale();
    this.maskBottomSprite = sprite;

    if(this.scrollerSystemIndex.includes(this.params.systemIndex)) {
      this.scrollerUpdate();
    }
 
    designX = 134;
    designY = 674;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'btn_back');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    this.btnBackSprite = sprite;
    // this.btnBackSprite.setVisible(false);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      this.btnBackSprite.setAlpha(0.5);
      this.btnBackSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      this.btnBackSprite.setAlpha(1.0);

      if(this.btnBackSprite.isPressed) {
        this.back();
      }

      this.btnBackSprite.isPressed = false;
    });

    // 本科生
    if(!GC.onlyGraduate.includes(this.params.systemIndex)) {
      designX = 0;
      designY = 572;
      sprite = this.add.image(
        this.scaler.scaleBgX(designX, 'right'),
        this.scaler.scaleY(designY),
        'btn_undergraduate');
      sprite.setOrigin(1, 0.5);
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        xlocation: 'right',
        designX: designX,
        designY: designY,
        enableClick: true,
        alpha: 1,
      };
      this.btnUndergraduateSprite = sprite;
  
      sprite.setInteractive();
  
      sprite.on("pointerdown", pointer => {
        this.btnUndergraduateSprite.setAlpha(0.5);
        this.btnUndergraduateSprite.isPressed = true;
      });
  
      sprite.on("pointerup", pointer => {
        this.btnUndergraduateSprite.setAlpha(1.0);
  
        if(this.btnUndergraduateSprite.isPressed) {
          this.nextPage(0, true);
        }

        this.btnUndergraduateSprite.isPressed = false;
      });
    }

    // 研究生
    designX = 0;
    designY = 869;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_graduate');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    this.btnGraduateSprite = sprite;

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      this.btnGraduateSprite.setAlpha(0.5);

      this.btnGraduateSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      this.btnGraduateSprite.setAlpha(1.0);

      if(this.btnGraduateSprite.isPressed) {
        this.nextPage(1, true);
      }

      this.btnGraduateSprite.isPressed = false;
    });
    
    
    this.input.on('pointerup', function () {
      if(!this.inputDisable) {
        utils.updateButtonsAlpha(this);
      }
    }, this);

    utils.disableAllButtons(this);

    if(this.params.autoJump === 'personal') {
      this.scene.get('sceneArea').hide();
      const blocks = [self.graduateBlockSprite];
      if(self.undergraduateBlockSprite) {
        blocks.push(self.undergraduateBlockSprite);
      }
      blocks.forEach(s => {
        s.setVisible(true);
      });
      
      this.nextPage(this.params.categoryIndex, false);
    } else if(this.params.autoJump === 'system') {
      this.scene.get('sceneArea').hide();
      this.scene.get('sceneBottomLine').show();
      const blocks = [self.graduateBlockSprite];
      if(self.undergraduateBlockSprite) {
        blocks.push(self.undergraduateBlockSprite);
      }
      blocks.forEach(s => {
        s.setVisible(true);
      });

      this.cameras.main.setVisible(true);
      utils.enableAllButtons(self);

      if(this.scrollerSystemIndex.includes(this.params.systemIndex)) {
        this.scroller.autoScroller();
      }
    } else {
      this.scene.get('sceneArea').hide();
      this.enterAnimation(function() {
        utils.enableAllButtons(self); 
        self.resized();  
        
        if(self.scrollerSystemIndex.includes(self.params.systemIndex)) {
          self.scroller.autoScroller();
        }
      });
    }
  },

  update: function(){
    if(this.animationing) {
      return;
    }
    
    this.scrollerUpdate();
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
    this.scrollerUpdate();
  },

  back: function() {
    const self = this;
    utils.disableAllButtons(this);

    // 向右移出
    const targets = [this.prefaceNameSprite, this.prefaceSprite, this.maskTopSprite, this.maskBottomSprite,this.btnGraduateSprite, this.graduateBlockSprite];
    if(this.undergraduateBlockSprite) {
      targets.push(this.undergraduateBlockSprite)
    }
    if(this.btnUndergraduateSprite) {
      targets.push(this.btnUndergraduateSprite)
    }
    utils.slideAimation(this, targets, 'back', GC.changePageDuration, function() {
      
    });
    
    utils.maskGroupTweenCustom(this, this.maskGroup, {
      x: this.params.systemPosition.x,
      y: this.params.systemPosition.y,
      scale: this.maskGroup.getChildren()[0].keepData.initialScale * 0.81,
    },
    GC.changePageDuration,
    null,
    function() {
      self.scene.stop();
      self.scene.get('sceneArea').backAnimation();
    });
  },

  nextPage: function(categoryIndex, animation) {
    const self = this;
    this.animationing = true;
    window.custom.selected.categoryIndex = categoryIndex;

    if(this.scroller) {
      this.scroller.stopScroller();
    }

    this.scene.get('sceneLoading').changeColor();
    this.scene.get('sceneLoading').cameraReset();
    this.scene.get('sceneLoading').setLoadingProgress(0);

    if(animation) {
      utils.sceneSlideAimation(this, 'out', function() {
        // self.scene.stop();
        self.animationing = false;
        utils.hide(self);
  
        self.toMainPage(categoryIndex);
      });
  
      this.scene.get('sceneTitle').slideOutAnimation();
      this.scene.get('sceneBottomLine').outAnimation();

      this.scene.get('sceneLoading').enterAnimation();
    } else {
      this.animationing = false;
      this.toMainPage(categoryIndex);
    }
    
    this.scene.launch('sceneSideBar', {
      systemIndex: this.params.systemIndex,
      categoryIndex: categoryIndex,
      noAnimation: !animation,
    });
    
  },

  toMainPage: function(categoryIndex) { // 0->本科生, 1->研究生
    var self = this;
    var systemList = window.custom.systemList;
    
    var system = GC.systemNames[this.params.systemIndex];
    var category = ['本科生', '研究生'][categoryIndex];

    var latest = '';
    for(var key in systemList){
      if(systemList[key].label.indexOf(system) >= 0 && systemList[key].label.indexOf(category) >= 0){
        latest = systemList[key].latest;
        break;
      }
    }

    window.getRegion =$.ajax({
      url: latest,
      type: "GET",
      async: true,
      success: function(data) {
        console.log('GET IMAGES SUCCESS!');
        let regionsRaw = data.regions;

        window.publicPath = data.publicPath;
        window.theme = data.theme;
        // self.getSystem(data.label);

        //remove avatar
        // regionsRaw.forEach(region => {
        //   if(region.meta.avatar && region.meta.avatar.texture) {
        //     for(var texture in region.preloadMaterials.images) {
        //       if(texture == region.meta.avatar.texture) {
        //         delete region.preloadMaterials.images[texture]
        //       }
        //     }
        //   }
          
        // });

        utils.setCookie('selected', encodeURIComponent(JSON.stringify(window.custom.selected)));
        if(window.custom.locator == null && regionsRaw.length > 0){
          utils.setCookie('locator', regionsRaw[0].meta.locator);
        }

        self.scene.launch('sceneMain', {
          regions: regionsRaw,
          theme: window.theme,
          multiple: 'multiple'
        });
        console.log('TO MAIN SCENE!!!');
      }, error : function(response) {
        console.log("GET IMAGES FAILED!");
        console.log(JSON.stringify(response));
      }
    });
  },

  // 打开
  enterAnimation: function(func) {
    this.animationing = true;
    var self = this;
    this.cameras.main.setVisible(true);

    const moveInTargets = [this.prefaceNameSprite, this.prefaceSprite, this.maskTopSprite, this.maskBottomSprite, this.btnGraduateSprite];
    if(this.btnUndergraduateSprite) {
      moveInTargets.push(this.btnUndergraduateSprite);
    }
    moveInTargets.forEach(sprite => {
      sprite.setVisible(false);
    });
    
    // 2.选择的系向上移动、变大
    utils.setMaskGroupScale(this.maskGroup, 0.81);
    utils.setMaskGroupPosition(this.maskGroup, this.params.systemPosition.x, this.params.systemPosition.y);

    utils.maskGroupTween(
      this,
      this.maskGroup,
      {
        value: -365 + this.maskGroup.getChildren()[0].width/2,
        location: 'center',
      },
      {
        value: 318,
        location: 'top',
      },
      GC.changePageDuration/2,
      {
        scale: this.maskGroup.getChildren()[0].keepData.initialScale,
      },
      null,
      function() {
        //  3.系主任寄语从右侧移入
        const targets = [self.prefaceNameSprite, self.prefaceSprite, self.maskTopSprite, self.maskBottomSprite, self.btnGraduateSprite];
        if(self.btnUndergraduateSprite) {
          targets.push(self.btnUndergraduateSprite);
        }
        moveInTargets.forEach(sprite => {
          sprite.setVisible(true);
        })
        utils.slideAimation(self, targets, 'in', GC.changePageDuration, function() {

          const blocks = [self.graduateBlockSprite];
          if(self.undergraduateBlockSprite) {
            blocks.push(self.undergraduateBlockSprite);
          }
          blocks.forEach(s => {
            s.setVisible(true);
          });
          utils.slideAimation(self, blocks, 'in', GC.changePageDuration/3, function() {
            self.animationing = false;

            if(func) {
              func();
            }
          });          
        });
      }
    );
  },

  // 关闭
  backAnimation: function(func) {
    const self = this;
    this.resized();
    this.animationing = true;
    this.cameras.main.setVisible(true);

    if(this.scroller) {
      this.scroller.stopScroller();
    }

    utils.sceneSlideAimation(this, 'leftIn', function() {
      if(func) {
        func()
      }
    });
  },

  // 本研切换
  changeCategory: function() {
    const self = this;
    this.backAnimation(function() {
      const categoryIndex = window.custom.selected.categoryIndex;
      let targetCategoryIndex
      if(categoryIndex === 0) {
        targetCategoryIndex = 1
      } else {
        targetCategoryIndex = 0;
      }

      self.nextPage(targetCategoryIndex, true);
    });
  },

  // 返回
  backToSystemList: function() {
    const self = this;
    this.backAnimation(function() {
      self.back();
    });
  },

  cameraReset: function() {
    this.cameras.main.x = 0;
    this.cameras.main.y = 0;
    this.cameras.main.setVisible(false);
  },

  updateFrame: function() {
    this.bgSprite.setTexture('animation_bg_' + animationFrame);

    if(this.undergraduateBlockSprite && this.undergraduateBlockSprite.active) {
      this.undergraduateBlockSprite.setTexture('animation_bg_' + animationFrame);
    }
    this.graduateBlockSprite.setTexture('animation_bg_' + animationFrame);
  },

  scrollerUpdate: function() {
    if(this.scrollerSystemIndex.includes(this.params.systemIndex)) {
      this.scroller.checkEnding();
      this.scroller.checkCrop();

      if(this.scroller.firstEnd) {
        this.maskTopSprite.alpha = 0;
      } else {
        this.maskTopSprite.alpha = 1;
      }

      if(this.scroller.lastEnd) {
        this.maskBottomSprite.alpha = 0;
      } else {
        this.maskBottomSprite.alpha = 1;
      }
    }
  },
});
export default SceneSystemPreface;