import gallerybdd0 from "../dataset/galleries-bdd0.txt";
import shareIconImg from '../images/share_icon.jpg';
// var vConsole = new VConsole();

var galleriesMaps = {
  // 'https://exhibition.ad.tsinghua.edu.cn': gallery18df,
  // 'http://exhibition-origin.ad.onekind.com.cn': galleryd9db,
  // 'http://exhibition-staging.ad.onekind.com.cn': gallery39c6,
  'default': gallerybdd0
};

function Utils() {
};

Utils.prototype.fetchRegions = function() {
  let jsonData = galleriesMaps[window.location.origin] || galleriesMaps['default'];
  $.ajax({
    //url: 'http://exhibition-staging.ad.onekind.com.cn/galleries',
    url: jsonData,
    type: "GET",
    async: true,
    success: function(data) {
      // console.log('GET IMAGES SUCCESS!');
      // console.log(data);
      window.custom.systemList = (typeof data === 'string') ? JSON.parse(data) : data;

    }, error : function(response) {
      console.log("GET IMAGES FAILED!");
      console.log(JSON.stringify(response));
    }
  });
};

Utils.prototype.setCookie = function(name,value)
{
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days*24*60*60*1000);
  // console.log(name + '..' + decodeURIComponent(value));
  document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
};

Utils.prototype.getCookie = function(name)
{
  var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");

  if(arr = document.cookie.match(reg))

    return unescape(arr[2]);
  else
    return null;
};

Utils.prototype.delCookie = function(name)
{
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = this.getCookie(name);
  if(cval != null)
    document.cookie= name + "="+cval+";expires="+exp.toGMTString();
};

Utils.prototype.isIphone = function(){
  return /iphone/gi.test(navigator.userAgent);
};

Utils.prototype.isAndroid = function(){
  var u = navigator.userAgent;
  console.log(u)
  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    return true
  } else {
    return false
  }
};

Utils.prototype.isIphoneXORIphone11Wechat = function(){
  if (typeof window !== 'undefined' && window) {
    return /iphone/gi.test(navigator.userAgent) && /micromessenger/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
  }
  return false;
};

Utils.prototype.isWechat = function(){
  if (typeof window !== 'undefined' && window) {
    return /micromessenger/gi.test(window.navigator.userAgent);
  }
  return false;
};

Utils.prototype.isIOS16 = function(){
  var str= navigator.userAgent.toLowerCase(); 
  var ver = str.match(/cpu iphone os (\d+)\_/);
  if(ver && ver.length > 1 && ver[1] === '16') {
    return true
  }
  return false
};

Utils.prototype.getUrlParam = function(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

Utils.prototype.wechatShare = function(){
  let url = window.location.href;

  let configUrl = url.replace(/exhibition.+/,'wx.ad.onekind.com.cn/wxconfig?url=' + document.location.href);

  let sharedPicUrl = window.location.origin + '/2023/' + shareIconImg;
  let sharedPageUrl = 'https://exhibition.ad.tsinghua.edu.cn/2023/';

  // console.log(configUrl);
  // console.log(sharedPageUrl);
  // console.log(sharedPicUrl);

  $.getJSON(configUrl,function(config){
    console.log('wechat share..');
    // console.log(config);
    // alert (data.result);
    //alert(JSON.stringify(config));

    config.debug = false;
    config.jsApiList = [
      'updateTimelineShareData',
      'updateAppMessageShareData',
      'onMenuShareTimeline',
      'onMenuShareAppMessage'
    ];


    // console.log('config....');
    // console.log(JSON.stringify(config));
    window.wx = wx;
    wx.config(config);

    wx.ready(function(){
      
      wx.updateTimelineShareData && wx.updateTimelineShareData({
        title: '清华大学美术学院2023届线上毕业作品展',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

      wx.updateAppMessageShareData && wx.updateAppMessageShareData({
        title: '清华大学美术学院2023届线上毕业作品展',
        desc: '',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

      // 即将废弃
      wx.onMenuShareTimeline && wx.onMenuShareTimeline({
        title: '清华大学美术学院2023届线上毕业作品展',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

      // 即将废弃
      wx.onMenuShareAppMessage && wx.onMenuShareAppMessage({
        title: '清华大学美术学院2023届线上毕业作品展',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        desc: '',
        type: '',
        dataUrl: '',
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

    });

    wx.error(function(res){
      console.log('error!!');
      console.log(res);
    });
  });
};

Utils.prototype.getVideoColor = function(video){
  var r= 0, g = 0, b = 0;
  if(video.width != undefined && video.width > 0 && video.height != undefined && video.height > 0){
      // video.video.crossOrigin = 'anonymous'
      // video.video.setAttribute('crossOrigin', 'anonymous');//处理跨域
      var canvas = video.snapshot().canvas;
      var context = canvas.getContext("2d");
      // 获取像素数据
      var data = context.getImageData(0, 0, canvas.width, canvas.height).data;

      // 取所有像素的平均值
      for (var row = 0; row < canvas.height; row++) {
          for (var col = 0; col < canvas.width; col++) {
              r += data[((canvas.width * row) + col) * 4];
              g += data[((canvas.width * row) + col) * 4 + 1];
              b += data[((canvas.width * row) + col) * 4 + 2];
          }
      }

      // 求取平均值
      r /= (canvas.width * canvas.height);
      g /= (canvas.width * canvas.height);
      b /= (canvas.width * canvas.height);

      // 将最终的值取整
      r = Math.round(r);
      g = Math.round(g);
      b = Math.round(b);
  }
  return {r: r, g: g, b: b};
};

Utils.prototype.getVideoColor2 = function(scene, videoPosition) {
  const self = this;
  scene.game.renderer.snapshotArea(videoPosition.x, videoPosition.y, 100, 100, (image) => {
    console.log('image', image)
    console.log(self.getImageColor(image));
  }, 'image/jpeg')
};

Utils.prototype.getImageColor = function(img) {
  var canvas = document.createElement('canvas')
  canvas.width = img.width;
  canvas.height = img.height;

  var context = canvas.getContext("2d");
  img.crossOrigin = "Anonymous"
  context.drawImage(img, 0, 0, canvas.width, canvas.height);

  // 获取像素数据
  var data = context.getImageData(0, 0, img.width, img.height).data;
  console.log(data)
  var r = 1,
    g = 1,
    b = 1;
  // 取所有像素的平均值
  for (var row = 0; row < img.height; row++) {
    for (var col = 0; col < img.width; col++) {
      // console.log(data[((img.width * row) + col) * 4])
      if (row == 0) {
        r += data[((img.width * row) + col)];
        g += data[((img.width * row) + col) + 1];
        b += data[((img.width * row) + col) + 2];
      } else {
        r += data[((img.width * row) + col) * 4];
        g += data[((img.width * row) + col) * 4 + 1];
        b += data[((img.width * row) + col) * 4 + 2];
      }
    }
  }

  // 求取平均值
  r /= (img.width * img.height);
  g /= (img.width * img.height);
  b /= (img.width * img.height);

  // 将最终的值取整
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);

  return {
    r: r,
    g: g,
    b: b,
  }
};

Utils.prototype.timeFormate = function (timeInSeconds) {
  let result = parseInt(timeInSeconds);
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

  let res = '';
  if(h !== '00') res += `${h}:`;
  res += `${m}:`;
  res += `${s}`;
  return res;
};

Utils.prototype.sceneSlideAimation = function(scene, direction, func) {
  if(!scene.cameras.main) {
    return;
  }
  let startX, targetX;
  let duration = GC.changePageDuration;
  const width = Math.max(scene.scaler.width, scene.scaler.height);
  if(direction == 'in') {
    startX = width;
    targetX = 0;
  }
  if(direction == 'out') {
    startX = 0;
    targetX = -width;
  }
  if(direction == 'back') {
    startX = 0;
    targetX = width;
  }
  if(direction == 'leftIn') {
    startX = -width;
    targetX = 0;
  }
  
  // var ease = 'Linear';
  var ease = 'Sine.easeInOut';
  if(scene.scaler.getDirection() == 'Horizontal') {
    scene.tweens.add({
      targets: scene.cameras.main,
      x: {
        start: startX,
        to: targetX
      },
      duration: duration,
      delay: 0,
      ease: ease,
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        if(func) {
          func();
        }
      },
    }); 
  } else {
    scene.tweens.add({
      targets: scene.cameras.main,
      y: {
        start: startX,
        to: targetX
      },
      duration: duration,
      delay: 0,
      ease: ease,
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        if(func) {
          func();
        }
      },
    }); 
  }
  
};

Utils.prototype.slideAimation = function(scene, targets, direction, duration = GC.changePageDuration, func) {
  let startX, targetX;
  // let duration = GC.changePageDuration;
  // var ease = 'Linear';
  var ease = 'Sine.easeInOut';
  const width = Math.max(scene.scaler.width, scene.scaler.height);


  // if(direction == 'out') {
  //   startX = 0;
  //   targetX = -scene.scaler.scaleX(scene.scaler.designRefWidth());
  // }
  // if(direction == 'back') {
  //   startX = 0;
  //   targetX = scene.scaler.scaleX(scene.scaler.designRefWidth());
  // }
  // if(direction == 'leftIn') {
  //   startX = -scene.scaler.scaleX(scene.scaler.designRefWidth());
  //   targetX = 0;
  // }

  let completeNum = 0;
  targets.forEach(sprite => {
    if(direction == 'in') {
      const designX = sprite.keepData.designX;
      const xlocation = sprite.keepData.xlocation;
      startX = scene.scaler.scaleBgX(designX, xlocation) + width;
      targetX = scene.scaler.scaleBgX(designX, xlocation); 
    }
    if(direction == 'back') {
      const designX = sprite.keepData.designX;
      const xlocation = sprite.keepData.xlocation;
      startX = scene.scaler.scaleBgX(designX, xlocation);
      targetX = scene.scaler.scaleBgX(designX, xlocation) + width;
    }

    if(direction == 'out') {
      const designX = sprite.keepData.designX;
      const xlocation = sprite.keepData.xlocation;
      startX = sprite.x;//scene.scaler.scaleBgX(designX, xlocation);
      targetX = scene.scaler.scaleBgX(designX, xlocation) - width;
    }
    if(direction == 'leftIn') {
      const designX = sprite.keepData.designX;
      const xlocation = sprite.keepData.xlocation;
      startX = scene.scaler.scaleBgX(designX, xlocation) - width;
      targetX = scene.scaler.scaleBgX(designX, xlocation);
    }

    sprite.x = startX;
    scene.tweens.add({
      targets: sprite,
      x: {
        start: startX,
        to: targetX
      },
      duration: duration,
      delay: 0,
      ease: ease,
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        completeNum ++;
        if(completeNum >= targets.length && func) {
          func();
        }
      },
    });
  })
};

Utils.prototype.updateButtonsAlpha = function(scene) {
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData && sprite.keepData.enableClick) {
      sprite.alpha = sprite.keepData.alpha;
      sprite.isPressed =  false;
    }
  });
};

Utils.prototype.enableAllButtons = function(scene) {
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData.enableClick) {
      sprite.setInteractive();
    }
  });
};

Utils.prototype.disableAllButtons = function(scene) {
  scene.inputDisable = true;
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData.enableClick) {
      sprite.disableInteractive();
    }
  });
};

Utils.prototype.resized = function (scene, targets) {
  var phaserElements = scene.children.list;
  if(targets) {
    phaserElements = targets;
  }
  for(var i = 0; i < phaserElements.length; i ++){
    var s = phaserElements[i];
    if(s.keepData && s.keepData.resized == true){
      if(s.keepData.offset == 'width') {
        s.keepData.width = s.keepData.initialWidth + window.sidebarOffset;
      }
      if(s.keepData.offset == 'x') {
        s.keepData.designX = s.keepData.initialX + window.sidebarOffset;
      }

      if(s.type == 'Video'){
        s.x = scene.scaler.scaleX(scene.scaler.designRefWidth()/2);
        s.y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2);
        scene.scaler.scaleSprite(s);
      } else{
        if(s.keepData.xlocation && s.keepData.xlocation == 'right'){
          s.x = scene.scaler.scaleBgX(s.keepData.designX, 'right');
        } else if(s.keepData.xlocation && s.keepData.xlocation == 'center'){
          s.x = scene.scaler.scaleBgX(s.keepData.designX, 'center');
        } else {
          s.x = scene.scaler.scaleBgX(s.keepData.designX);
        }

        if(s.keepData.scaleBgY) {
          s.y = scene.scaler.scaleBgY(s.keepData.designY, s.keepData.ylocation);
        } else {
          if(s.keepData.ylocation && s.keepData.ylocation == 'bottom'){
            s.y = scene.scaler.scaleY(scene.scaler.designRefHeight() + s.keepData.designY);
          } else if(s.keepData.ylocation && s.keepData.ylocation == 'center'){
            s.y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + s.keepData.designY);
          } else{
            s.y = scene.scaler.scaleY(s.keepData.designY);
          }
        }
        
        if(s.keepData.fill){
          scene.scaler.scaleSpriteFill(s);
        } else if(s.keepData.scaleByMax){
          scene.scaler.scaleSpriteByMax(s);
        } else if(s.keepData.scaleFillHeight) {
          scene.scaler.scaleSpriteFillHeight(s);
        } else if(s.keepData.scaleFillWidth) {
          scene.scaler.scaleSpriteFillWidth(s);
        } else if(s.keepData.scaleSpriteByMin) {
          scene.scaler.scaleSpriteByMin(s);
        } else {
          scene.scaler.scaleSprite(s);
        }

        if(s.keepData.initialScale != undefined){
          s.keepData.initialScale = s.scale;
        }

        if(s.keepData.initialHeight != undefined){
          s.keepData.initialHeight = s.displayHeight;
        }


        if(s.keepData.scale){
          s.scale *= s.keepData.scale;
        }

        if(s.keepData.scaleX){
          s.scaleX *= s.keepData.scaleX;
        }
  
        if(s.keepData.width){
          s.displayWidth = s.keepData.width * scene.scaler.vScale();
        }
  
        if(s.keepData.height){
          s.displayHeight = s.keepData.height * scene.scaler.vScale();
        }
      }
    }
  }
};

Utils.prototype.arrowAnimation = function(scene, targetSprite, borderSprite) {
  let duration = 1200;
  const w = borderSprite.width/2 + targetSprite.width;
  let startX = scene.scaler.scaleBgX(targetSprite.keepData.designX, 'right');
  const targetX = scene.scaler.scaleBgX(targetSprite.keepData.designX + w, 'right');

  if(targetSprite.tween) {
    targetSprite.tween.remove();
  }
  targetSprite.tween = scene.tweens.add({
    targets: targetSprite,
    x: {
      start: startX,
      to: targetX,
    },
    duration: duration,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    yoyo: false,
    onComplete: function () {
      duration = 2400;
      startX = scene.scaler.scaleBgX(targetSprite.keepData.designX - w, 'right');
      targetSprite.tween = scene.tweens.add({
        targets: targetSprite,
        x: {
          start: startX,
          to: targetX,
        },
        duration: duration,
        delay: 0,
        ease: 'Linear',
        repeat: -1,
        yoyo: false,
        onComplete: function () {
        },
        onRepeat: function () {
        },
      });
    },
  }); 
};

Utils.prototype.stopArrowAnimation = function(scene, targetSprite) {
  targetSprite.x = scene.scaler.scaleBgX(targetSprite.keepData.designX, 'right')
  targetSprite.tween.remove();
};

Utils.prototype.arrowCrop = function(scene, targetSprite, borderSprite, rightOffset = 0) {
  let cropX = 0;
  let cropY = 0;
  let cropWidth = targetSprite.width;
  let cropHeight = targetSprite.height;
  let width;
  // 左侧
  if(targetSprite.x - targetSprite.displayWidth/2 < borderSprite.x - borderSprite.displayWidth/2) {
    cropX = borderSprite.x - borderSprite.displayWidth/2;
    width = targetSprite.x + targetSprite.displayWidth/2 - (borderSprite.x - borderSprite.displayWidth/2);
    cropWidth = scene.scaler.getDesignX(width);
  }

  const offsetR = scene.scaler.hScale() * rightOffset;
  // 右侧
  if(targetSprite.x + targetSprite.displayWidth/2 > borderSprite.x + borderSprite.displayWidth/2 + offsetR) {
    cropX = 0;
    width = borderSprite.x + borderSprite.displayWidth/2 + offsetR - (targetSprite.x - targetSprite.displayWidth/2)
    cropWidth = scene.scaler.getDesignX(width);
  }
  
  targetSprite.setCrop(cropX, cropY, cropWidth, cropHeight);
};

Utils.prototype.fadeOutAnimation = function(scene, targets, duration = GC.changePageDuration) {
  return new Promise(function (resolve, reject) {
    scene.tweens.add({
      targets: targets,
      alpha: {
        start: 1,
        to: 0,
      },
      duration: duration,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        resolve();
      },
    }); 
  })
};

Utils.prototype.fadeInAnimation = function(scene, targets, duration = GC.changePageDuration) {
  if(targets instanceof  Array) {
    targets.forEach(s => {
      s.alpha = 0;
    })
  } else {
    targets.alpha = 0;
  }
  return new Promise(function (resolve, reject) {
    scene.tweens.add({
      targets: targets,
      alpha: {
        start: 0,
        to: 1,
      },
      duration: duration,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        resolve();
      },
    }); 
  })
};

Utils.prototype.hide = function(scene) {
  // scene.cameras.main.setVisible(false);

  scene.cameras.cameras.forEach(c => {
    c.setVisible(false)
  })
};

Utils.prototype.show = function(scene) {
  scene.cameras.main.setVisible(true);
  scene.cameras.main.setAlpha(1);
}

Utils.prototype.getColor = function() {
  var color = 'pink';
  if(window.custom.selected.categoryIndex === 0) {
    // 研究生
    color = 'blue';
  }
  return color;
}

Utils.prototype.createMaskGroup = function(scene, key, designX, designY, xlocation = 'left', ylocation = 'top') {
  const width = scene.textures.get(key).getSourceImage().width;
  const height = scene.textures.get(key).getSourceImage().height;

  const maskW = scene.scaler.designRefWidth() * 5;
  const maskH = scene.scaler.designRefHeight() * 2;

  const group = scene.add.group();
  const offet = 3;

  var y = scene.scaler.scaleY(designY);
  if(ylocation && ylocation == 'bottom'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight() + designY);
  } else if(ylocation && ylocation == 'center'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + designY);
  }
  var sprite = scene.add.sprite(
    scene.scaler.scaleBgX(designX, xlocation),
    y,
    key
  );
  sprite.setOrigin(0.5, 0.5);
  scene.scaler.scaleSprite(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: xlocation,
    ylocation: ylocation,
    designX: designX,
    designY: designY,
    initialScale: sprite.scale
  };
  // sprite.scale *= sprite.keepData.scale;
  group.add(sprite);

  var rectDesignX,  rectDesignY;

  // top
  rectDesignX = designX;
  rectDesignY = designY - height/2 + offet;

  y = scene.scaler.scaleY(rectDesignY);
  if(ylocation && ylocation == 'bottom'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight() + rectDesignY);
  } else if(ylocation && ylocation == 'center'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + rectDesignY);
  }
  sprite = scene.add.rectangle(
    scene.scaler.scaleBgX(rectDesignX, xlocation),
    y,
    maskW,
    maskH
    , 0xffffff, 1);
  sprite.setOrigin(0.5, 1);
  scene.scaler.scaleSprite(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: xlocation,
    ylocation: ylocation,
    designX: rectDesignX,
    designY: rectDesignY
  };
  group.add(sprite);

  // bottom
  rectDesignX = designX;
  rectDesignY = designY + height/2 - offet;
  y = scene.scaler.scaleY(rectDesignY);
  if(ylocation && ylocation == 'bottom'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight() + rectDesignY);
  } else if(ylocation && ylocation == 'center'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + rectDesignY);
  }
  sprite = scene.add.rectangle(
    scene.scaler.scaleBgX(rectDesignX, xlocation),
    y,
    maskW,
    maskH
    , 0xffffff, 1);
  sprite.setOrigin(0.5, 0);
  scene.scaler.scaleSprite(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: xlocation,
    ylocation: ylocation,
    designX: rectDesignX,
    designY: rectDesignY
  };
  group.add(sprite);

  // left
  rectDesignX = designX - width/2 + offet;
  rectDesignY = designY;
  y = scene.scaler.scaleY(rectDesignY);
  if(ylocation && ylocation == 'bottom'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight() + rectDesignY);
  } else if(ylocation && ylocation == 'center'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + rectDesignY);
  }
  sprite = scene.add.rectangle(
    scene.scaler.scaleBgX(rectDesignX, xlocation),
    y,
    maskW,
    height + 10
    , 0xffffff, 1);
  sprite.setOrigin(1, 0.5);
  scene.scaler.scaleSprite(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: xlocation,
    ylocation: ylocation,
    designX: rectDesignX,
    designY: rectDesignY
  };
  group.add(sprite);

  // right
  rectDesignX = designX + width/2 - offet;
  rectDesignY = designY;
  y = scene.scaler.scaleY(rectDesignY);
  if(ylocation && ylocation == 'bottom'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight() + rectDesignY);
  } else if(ylocation && ylocation == 'center'){
    y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + rectDesignY);
  }
  sprite = scene.add.rectangle(
    scene.scaler.scaleBgX(rectDesignX, xlocation),
    y,
    maskW,
    height + 10
    , 0xffffff, 1);
  sprite.setOrigin(0, 0.5);
  scene.scaler.scaleSprite(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: xlocation,
    ylocation: ylocation,
    designX: rectDesignX,
    designY: rectDesignY
  };
  group.add(sprite);

  return group;
}

Utils.prototype.maskGroupTween = function(scene, group, xParams, yParams, duration= GC.changePageDuration, customTweenParams, updateFunc, completeFunc) {
  const maskSprite = group.getChildren()[0];
  const topSprite = group.getChildren()[1];
  const bottomSprite = group.getChildren()[2];
  const leftSprite = group.getChildren()[3];
  const rightSprite = group.getChildren()[4];

  let targetX = scene.scaler.scaleBgX(xParams.value, xParams.location);

  let targetY = scene.scaler.scaleY(yParams.value);
  if(yParams.location == 'bottom'){
    targetY = scene.scaler.scaleY(scene.scaler.designRefHeight() + yParams.value);
  } else if(yParams.location == 'center'){
    targetY = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + yParams.value);
  }

  scene.tweens.add({
    targets: maskSprite,
    x: targetX,
    y: targetY,
    ...customTweenParams,
    duration: duration,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    yoyo: false,
    onUpdate: function() {
      topSprite.x = maskSprite.x;
      topSprite.y = maskSprite.y - maskSprite.displayHeight/2 + 1;

      bottomSprite.x = maskSprite.x;
      bottomSprite.y = maskSprite.y + maskSprite.displayHeight/2 - 1;

      leftSprite.x = maskSprite.x - maskSprite.displayWidth/2 + 1;
      leftSprite.y = maskSprite.y;

      rightSprite.x = maskSprite.x + maskSprite.displayWidth/2 - 1;
      rightSprite.y = maskSprite.y;
      
      if(updateFunc) {
        updateFunc();
      }
    },
    onComplete: function () {
      maskSprite.keepData.designX = xParams.value;
      maskSprite.keepData.designY = yParams.value;

      topSprite.keepData.designX = xParams.value;
      topSprite.keepData.designY = yParams.value - maskSprite.height/2 + 1;

      bottomSprite.keepData.designX = xParams.value;
      bottomSprite.keepData.designY = yParams.value + maskSprite.height/2 - 1;

      leftSprite.keepData.designX = xParams.value - maskSprite.width/2 + 1;
      leftSprite.keepData.designY = yParams.value;

      rightSprite.keepData.designX = xParams.value + maskSprite.width/2 - 1;
      rightSprite.keepData.designY = yParams.value;

      [maskSprite, topSprite, bottomSprite, leftSprite, rightSprite].forEach(s => {
        s.keepData.xlocation = xParams.location;
        s.keepData.ylocation = yParams.location;
      });

      if(completeFunc) {
        completeFunc();
      }
    },
  });
}

Utils.prototype.maskGroupTweenCustom = function(scene, group, tweenParams, duration = GC.changePageDuration, updateFunc, completeFunc) {
  const maskSprite = group.getChildren()[0];
  const topSprite = group.getChildren()[1];
  const bottomSprite = group.getChildren()[2];
  const leftSprite = group.getChildren()[3];
  const rightSprite = group.getChildren()[4];

  scene.tweens.add({
    targets: maskSprite,
    ...tweenParams,
    duration: duration,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    yoyo: false,
    onUpdate: function() {
      topSprite.x = maskSprite.x;
      topSprite.y = maskSprite.y - maskSprite.displayHeight/2 + 1;

      bottomSprite.x = maskSprite.x;
      bottomSprite.y = maskSprite.y + maskSprite.displayHeight/2 - 1;

      leftSprite.x = maskSprite.x - maskSprite.displayWidth/2 + 1;
      leftSprite.y = maskSprite.y;

      rightSprite.x = maskSprite.x + maskSprite.displayWidth/2 - 1;
      rightSprite.y = maskSprite.y;
      
      if(updateFunc) {
        updateFunc();
      }
    },
    onComplete: function () {
      if(completeFunc) {
        completeFunc();
      }
    },
  });
}

Utils.prototype.setMaskGroupPosition = function(group, x, y) {
  const maskSprite = group.getChildren()[0];
  const topSprite = group.getChildren()[1];
  const bottomSprite = group.getChildren()[2];
  const leftSprite = group.getChildren()[3];
  const rightSprite = group.getChildren()[4];

  maskSprite.x = x;
  maskSprite.y = y;

  topSprite.x = maskSprite.x;
  topSprite.y = maskSprite.y - maskSprite.displayHeight/2 + 1;

  bottomSprite.x = maskSprite.x;
  bottomSprite.y = maskSprite.y + maskSprite.displayHeight/2 - 1;

  leftSprite.x = maskSprite.x - maskSprite.displayWidth/2 + 1;
  leftSprite.y = maskSprite.y;

  rightSprite.x = maskSprite.x + maskSprite.displayWidth/2 - 1;
  rightSprite.y = maskSprite.y;
}

Utils.prototype.setMaskGroupScale = function(group, scale) {
  const maskSprite = group.getChildren()[0];

  maskSprite.scale = maskSprite.keepData.initialScale * scale;

  this.setMaskGroupPosition(group, maskSprite.x, maskSprite.y)
}

export default Utils;