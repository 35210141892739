'use strict';

var SceneTitle = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneTitle ()
  {
    Phaser.Scene.call(this, { key: 'sceneTitle', active: false });
  },

  init: function(params){
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.currentScene = 'sceneTitle';

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;
    
    designX = 64;
    designY = 50;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'title_left'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY,
      offset: 'x',
      initialX: designX,
    };

    designX = -64;
    designY = 50;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'title_right'
    );
    sprite.setOrigin(1, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
    };
    
    this.hide();
  },

  update: function() {
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  hide: function() {
    this.cameras.main.setVisible(false);
  },

  show: function() {
    this.cameras.main.setVisible(true);
  },

  enterAnimation: function() {
    this.show();
    utils.fadeInAnimation(this, this.cameras.main, GC.changePageDuration / 2);
  },

  outAnimation: function() {
    this.animationing = true;
    utils.fadeOutAnimation(this, this.cameras.main).then(res => {
      this.animationing = false;
    });
  },

  slideOutAnimation: function() {
    const self = this;
    this.animationing = true;
    utils.slideAimation(this, this.children.list, 'out', GC.changePageDuration, function() {
    // utils.sceneSlideAimation(this, 'out', function() {
      self.animationing = false;
      self.cameraReset();
    })
  },

  leftInAnimation: function() {
    const self = this;

    this.show();
    this.animationing = true;
    utils.slideAimation(this, this.children.list, 'leftIn', GC.changePageDuration, function() {
    // utils.sceneSlideAimation(this, 'leftIn', function() {
      self.animationing = false;
    },)
  },

  cameraReset: function() {
    this.cameras.main.x = 0;
    this.cameras.main.y = 0;
    this.cameras.main.setVisible(false);
  },
});
export default SceneTitle;