'use strict';

var ScenePreview = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function ScenePreview ()
  {
    Phaser.Scene.call(this, { key: 'scenePreview', active: false });
  },

  init: function(params){
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.scene.bringToTop();
  },

  preload: function ()
  {

  },

  create: function ()
  {
    // var self = this;
    // var sprite, designX, designY;

    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleBgX(designX, 'center'),
    //   this.scaler.scaleY(this.scaler.designRefHeight() /2, designY),
    //   'preview'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSprite(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   designX: designX,
    //   designY: designY,
    // };
    // sprite.alpha = 0.5;
  },

  update: function() {
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },
});
export default ScenePreview;