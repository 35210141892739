import '../styles/index.scss';
import '../styles/gallery.scss';

"use strict";

import SceneBoot from './scene-boot';
import ScenePreload from './scene-preload';
import ScenePreview from './scene-preview';
import SceneEnter from './scene-enter';
// import SceneVideo from './scene-video';
import SceneTitle from './scene-title';
import ScenePreface from './scene-preface';
import SceneSystemPreface from './scene-system-preface';
import SceneArea from './scene-area';
import SceneMain from './scene-main';
// import SceneAuthors from './scene-authors';
import SceneLoading from './scene-loading';
import SceneSidebar from './scene-sidebar';
import SceneBottomLine from './scene-bottom-line';

import Utils from './utils';
import GC from "./const";

window.utils = new Utils();
window.GC = GC;

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://41db8880c7e54ab0b4fc3dbaead9240e@sentry.f.onekind.com.cn/6",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

// window.vconsole = new VConsole();
// console.log('#userAgent');
// console.log(window.navigator.userAgent);
window.isIOS16 = false;
window.dragStartTime = 0;
window.dragEndTime = 0;

window.custom = {
  systemList: {},
  selected: {
    systemIndex: -1,
    categoryIndex: -1,
  },
  locator: null,
  noTip: false
};

window.videoCannotPlay = false;

window.startImageLoaded = false;
window.bgImageLoaded = false;

var config = {
  // type: Phaser.AUTO,
  type: Phaser.CANVAS,
  // type: Phaser.WEBGL,
  width: 1920,
  height: 1080,
  scale: {
    mode: Phaser.DOM.RESIZE
    // mode: Phaser.Scale.FIT,
    // autoCenter: Phaser.Scale.CENTER_BOTH
  },
  transparent: true,
  parent: 'phaser-wrapper',
  scene: [SceneBoot, ScenePreload, SceneLoading, SceneEnter, ScenePreview, SceneTitle, SceneBottomLine, ScenePreface, SceneSystemPreface, SceneArea, SceneMain, SceneSidebar]
};

var game = new Phaser.Game(config);
window.game = game;
utils.fetchRegions();

window.sidebarOffset = 0;

Zepto(function($) {
  window.needOffset = utils.isIphoneXORIphone11Wechat();
  // window.needOffset = true;

  window.isIOS16 = utils.isIOS16();
  
  if(window.needOffset){
    window.sidebarOffset = 70;
  }
  // window.videoCannotPlay = utils.getCookie('videoCannotPlay') == 'true' ? true: false;

  utils.wechatShare();

  // window.onbeforeunload = ()=>{
  //   utils.delCookie('selected');
  //   utils.delCookie('locator');
  // };

  // window.addEventListener('pagehide',()=>{
  //   // if(utils.isWechat()){
  //     utils.delCookie('selected');
  //     utils.delCookie('locator');
  //   // }
  // });

  // document.getElementById('btn-close').style.backgroundImage = 'url(' + window.location.origin + '/' + btnSkipImg + ')';
  // document.getElementById('btn-close-region').style.backgroundImage = 'url(' + window.location.origin + '/' + btnCloseImg + ')';
  // document.getElementById('btn-close-portfolio').style.backgroundImage = 'url(' + window.location.origin + '/' + btnCloseImg + ')';
});

window.animationFrame = 0;
window.animationStart = function() {
  var totalFrame = 71;
  var step = 1;
  setInterval(function() {
    animationFrame += step;
          
    if(animationFrame > totalFrame - 1){
      animationFrame = 0
    }
    // if(animationFrame > totalFrame - 1){
    //   step = -step;
    //   animationFrame += step;
    // }

    // if(animationFrame < 0){
    //   step = -step;
    //   animationFrame += step;
    // }
    const scenes = [
      'sceneEnter', 'scenePreface', 'sceneArea', 'sceneSystemPreface', 'sceneBottomLine'
    ];

    scenes.forEach(scene => {
      if(game.scene.isActive(scene) && game.scene.getScene(scene).scene.isVisible()) {
        game.scene.getScene(scene).updateFrame();
      }
    })
  }, 1000/25);
}