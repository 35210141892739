'use strict';
import Scroller from "./scroller";

var ScenePreface = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function ScenePreface ()
  {
    Phaser.Scene.call(this, { key: 'scenePreface', active: false });

    this.scrollerBounds = {
      top: 260,
      bottom: 765,
    }
  },

  init: function(params){
    var self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.currentScene = 'scenePreface';

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.sceneBoot.windowResized = true;

    this.scene.bringToTop();
    this.scene.get('sceneTitle').scene.bringToTop();
    this.scene.get('sceneBottomLine').scene.bringToTop();

    this.scene.get('scenePreview').scene.bringToTop();
  },

  preload: function ()
  {

  },

  create: function ()
  {
    // this.cameras.main.setVisible(false);

    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    var sprite = this.add.sprite(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'animation_bg'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      fill: true,
      xlocation: 'center',
      ylocation: 'center',
      designX: designX,
      designY: designY,
      // scale: 900/sprite.height,
    };
    this.bgSprite = sprite;
    // sprite.scale *= sprite.keepData.scale;

    if(this.textures.getTextureKeys().includes('animation_bg_' + animationFrame)) {
      this.bgSprite.setTexture('animation_bg_' + animationFrame);
    }

    this.maskGroup = utils.createMaskGroup(this, 'mask_rect', 400, -224, 'center', 'center');

    this.scroller = new Scroller(this, this.scaler, 'Vertical');
    this.dragBg = this.scroller.create();

    designX = 104;
    designY = 268;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'preface'
    );
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      alpha: 1,
    };
    sprite.visible = false;
    this.prefaceSprite = sprite;
    
    this.scroller.bgSprite = this.prefaceSprite;
    this.scroller.setDragSprites([this.prefaceSprite]);
    this.scroller.setScrollerBounds(this.scrollerBounds);

    designX = 105;
    designY = 250;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'mask_top'
    );
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.alpha = 0;
    this.maskTopSprite = sprite;

    designX = 105;
    designY = 770;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY, ''),
      'mask_bottom'
    );
    sprite.setOrigin(0.5, 1);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.alpha = 0;
    sprite.visible = false;
    this.maskBottomSprite = sprite;

    designX = 104;
    designY = 876;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'left'),
      this.scaler.scaleY(designY),
      'preface_title'
    );
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY
    };
    this.prefaceTitleSprite = sprite;

    designX = 452.5;
    designY = 915.5;
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'signature'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.visible = false;
    this.signatureSprite = sprite;

    // designX = 134;
    // designY = 675;
    // sprite = this.add.image(
    //   this.scaler.scaleBgX(designX, 'left'),
    //   this.scaler.scaleY(designY),
    //   'btn_back');
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSpriteByHeight(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'left',
    //   designX: designX,
    //   designY: designY,
    //   enableClick: true,
    //   alpha: 1,
    // };
    // this.btnBackSprite = sprite;

    // sprite.setInteractive();

    // sprite.on("pointerdown", pointer => {
    //   this.btnBackSprite.setAlpha(0.5);
    // });

    // sprite.on("pointerup", pointer => {
    //   this.btnBackSprite.setAlpha(1.0);

    //   this.back();
    // });

    designX = -145;
    designY = 675;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_next');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    this.btnNextSprite = sprite;

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      this.btnNextSprite.setAlpha(0.5);
      this.btnNextSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      this.btnNextSprite.setAlpha(1.0);

      if(this.btnNextSprite.isPressed) {
        this.next();
      }

      this.btnNextSprite.isPressed = false;
    });

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    this.resized();
    utils.disableAllButtons(this);
    this.enterAnimation(function() {
      utils.enableAllButtons(self);
      self.resized();

      self.scroller.autoScroller();
    });
  },

  update: function(){
    if(this.animationing) {
      return;
    }
    
    this.scrollerUpdate();
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
    this.scrollerUpdate();
  },

  back: function() {
    utils.disableAllButtons(this);

    this.backAnimation();
    this.scene.get('sceneTitle').outAnimation();
    this.scene.launch('sceneEnter');
  },

  next: function(){
    const self = this;
    utils.disableAllButtons(this);

    this.animationing = true;
    this.outAnimation(function() {
      self.animationing = false;
    });
  },

  enterAnimation: function(func) {
    this.animationing = true;
    const self = this;

    const fadeInTargets = [self.prefaceTitleSprite, self.btnNextSprite];
    fadeInTargets.forEach(s => {
      s.visible = false;
    });

    // 1.矩形块移动动效
    const maskSprite = this.maskGroup.getChildren()[0];
    utils.maskGroupTween(
      this,
      this.maskGroup,
      {
        value: 51 + 438/2,
        location: 'left',
      },
      {
        value: 886.5,
        location: 'top',
      },
      GC.changePageDuration,
      {
        displayWidth: this.scaler.hScale() * 448,
        displayHeight: this.scaler.vScale() * 233,
      },
      function() {
        if(maskSprite.x < self.scaler.scaleBgX(200, 'center')) {
          maskSprite.setTexture('mask_preface_0');
        }
        if(maskSprite.x < self.scaler.scaleBgX(0, 'center')) {
          maskSprite.setTexture('mask_preface_1');
        }
        if(maskSprite.x < self.scaler.scaleBgX(-200, 'center')) {
          maskSprite.setTexture('mask_preface_2');
        }
        if(maskSprite.x < self.scaler.scaleBgX(-50, 'center')) {
          maskSprite.setTexture('mask_preface_2');
        }
      }
    );
    
    // 2.渐显
    this.time.delayedCall(GC.changePageDuration/1.5, function(){
      self.scene.get('sceneTitle').enterAnimation();
      
      
      fadeInTargets.forEach(s => {
        s.visible = true;
      });
      utils.fadeInAnimation(self, fadeInTargets, GC.changePageDuration / 2).then(() => {
        // 文字从右侧移入
        const targets = [self.prefaceSprite, self.signatureSprite, self.maskTopSprite, self.maskBottomSprite];
        targets.forEach(sprite => {
          sprite.visible = true;
  
          const startX = self.scaler.scaleBgX(sprite.keepData.designX + self.scaler.designRefWidth(), 'center')
          const targetX = self.scaler.scaleBgX(sprite.keepData.designX, 'center')
          self.tweens.add({
            targets: sprite,
            x: {
              start: startX,
              to: targetX,
            },
            yoyo: false,
            repeat: 0,
            ease: 'Sine.easeInOut',
            duration: 1000,
            delay: 0,
            onComplete: function () {
              if(func) {
                func();
              }
              self.maskBottomSprite.alpha = 1;
              self.animationing = false;
            },
          });
        }, this); 
  
        self.scene.get('sceneBottomLine').enterAnimation();
      });
    }, this);
  },

  outAnimation: function(func) {
    var self = this;
    var duration = GC.changePageDuration;

    this.scroller.stopScroller();
    
    // 1.文字左侧移出
    const targets = [self.prefaceSprite, self.signatureSprite, self.maskTopSprite, self.maskBottomSprite];
    utils.slideAimation(this, targets, 'out');

    // 2.下一页按钮渐隐
    utils.fadeOutAnimation(this, [this.btnNextSprite, this.prefaceTitleSprite]);
    

    // 3.左下角梯形左侧移出
    this.time.delayedCall(duration, function(){
      // self.scene.get('sceneAnimation').rectMoveOut(); 

      utils.slideAimation(self, self.maskGroup.getChildren(), 'out');

      // 4.系别列表移入
      self.scene.launch('sceneArea', {});

      self.reScrollerTimer = self.time.delayedCall(GC.changePageDuration, function() {
        self.scene.stop();
        if(func) {
          func();
        }
      }, this);
    }, this); 
    
  },

  backAnimation: function() {
    this.animationing = true;
    
    this.scroller.stopScroller();
    
    this.maskGroup.getChildren().forEach(s => {
      s.keepData.notFadeOut = true;
    });
    const fadeOutTargets = [];
    this.children.list.forEach(s => {
      if(!(s.keepData && s.keepData.notFadeOut)) {
        fadeOutTargets.push(s);
      }
    });

    utils.fadeOutAnimation(this, fadeOutTargets).then(() => {
      this.scene.stop();
    });
    this.scene.get('sceneBottomLine').fadeOutAnimation();
  },

  scrollerUpdate: function() {
    if(this.scroller) {
      this.scroller.checkEnding();
      this.scroller.checkCrop();

      if(this.scroller.firstEnd) {
        this.maskTopSprite.alpha = 0;
      } else {
        this.maskTopSprite.alpha = 1;
      }

      if(this.scroller.lastEnd) {
        this.maskBottomSprite.alpha = 0;
      } else {
        this.maskBottomSprite.alpha = 1;
      }
    }
  },

  updateFrame: function() {
    this.bgSprite.setTexture('animation_bg_' + animationFrame);
  },
});
export default ScenePreface;