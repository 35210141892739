module.exports = {

  width: 0,
  height: 0,

  _designRefWidth: 0,
  _designRefHeight: 0,

  scaleMode: 'full',

  config: function(width, height, designRefWidth, designRefHeight) {
    this.width = width;
    this.height = height;
    this._designRefWidth = designRefWidth;
    this._designRefHeight = designRefHeight;

    //this._designRefWidth = 1080 * width / height;
    //this._designRefHeight = 1080;
  },

  designRefWidth: function() {
    if(this.getDirection() == 'Horizontal'){
      return this._designRefWidth;  
    } else{
      if(this.scaleMode == 'full'){
        return this._designRefHeight;
      }
      if(this.scaleMode == 'height'){
        return this._designRefHeight*1080/this._designRefWidth;
      }  
    }
  },

  designRefHeight: function() {
    if(this.getDirection() == 'Horizontal'){
      return this._designRefHeight;
    } else{
      if(this.scaleMode == 'full'){
        return this._designRefWidth;
      }
      if(this.scaleMode == 'height'){
        return this._designRefHeight;
      }  
    }
  },

  scaleSprite: function(sprite) {
    if(this.scaleMode == 'height' && this.getDirection() == 'Vertical'){
      sprite.setScale(this.vScale(), this.vScale());
    } else{
      sprite.setScale(this.hScale(), this.vScale());  
    }
  },

  scaleCamera: function(camera) {
    const width = camera.keepData.width;
    const height = camera.keepData.height;
    let w = width;
    let h = height;
    if(this.getDirection() != 'Horizontal'){
      w = height;
      h = width;
    }

    if(this.scaleMode == 'height' && this.getDirection() == 'Vertical'){
      camera.setSize(w * this.vScale(), h * this.vScale());
    } else{
      camera.setSize(w * this.hScale(), h * this.vScale());
      // if(camera.keepData.fillWidth) {
      //   camera.setSize(this.width, h * this.vScale());
      // }
    }
  },

  scaleSpriteByHeight: function(sprite) {
    if(this.getDirection() == 'Horizontal'){
      sprite.setScale(this.vScale(), this.vScale());
    } else{
      this.scaleSpriteByHeightVertical(sprite);
    }
    
  },

  setDisplaySize: function(sprite, width, height){
    if(this.getDirection() == 'Horizontal'){
      sprite.setDisplaySize(this.vScale() * width, this.vScale() * height);
    } else{
      sprite.setDisplaySize(this.vScaleVertical() * width, this.vScaleVertical() * height);
    }
  },

  scaleX: function (designX, _location) {
    let location = (_location || 'left').toLowerCase();
    let offset = 0;
    if(location =='center'){
      offset = this.designRefWidth()/2;
    } else if(location =='right'){
      offset = this.designRefWidth();
    }

    if(this.getDirection() == 'Horizontal'){
      //normal
      return offset + this.offsetPos().x + designX * this.hScale();
    } else{
      
      if(this.scaleMode == 'full'){
        return offset + this.offsetPos().y + designX * this.vScale();
      } else{
        return offset + this.offsetPos().x + designX * this.vScaleVertical();
      }
    }
  },

  scaleY: function(designY, _location) {
    let location = (_location || 'top').toLowerCase();
    let offset = 0;
    if(location =='center'){
      offset = this.designRefHeight()/2;
    } else if(location =='bottom'){
      offset = this.designRefHeight();
    }

    if(this.getDirection() == 'Horizontal'){
      //normal
      return offset + this.offsetPos().y + designY * this.vScale();
    } else{
      if(this.scaleMode == 'full'){
        return offset + this.offsetPos().x + designY * this.hScale();
      } else{
        return offset + this.offsetPos().y + designY * this.vScaleVertical();
      }
    }  
  },

  getDesignX: function (x) {
    return (x)/this.hScale();
  },

  getDesignY: function (y) {
    if(this.getDirection() == 'Horizontal'){
      return (y)/this.vScale();
    } else {
      return (y)/this.hScale();
    }
  },

  hScale: function() {
    if(this.scaleMode == 'height' && this.getDirection() == 'Vertical'){
      return this.hScaleVertical();
    } else{
      if(this.offsetPos().x != 0)
        return (this.height / this._designRefHeight);
      else
        return (this.width / this._designRefWidth);
    }
    
  },

  vScale: function() {
    if(this.scaleMode == 'height' && this.getDirection() == 'Vertical'){
      return this.vScaleVertical();
    } else{
      if(this.offsetPos().y != 0)
        return (this.width / this._designRefWidth);
      else
        return (this.height / this._designRefHeight);
    }   
  },

  offsetPos: function() {
    var offsetX = 0;
    var offsetY = 0;
    var ws = this.width / this._designRefWidth;
    var hs = this.height / this._designRefHeight;
    if(ws < hs){
        offsetY = (this.height - this._designRefHeight * this.width/this._designRefWidth)/2;
    }else{
        offsetX = (this.width - this._designRefWidth * this.height/this._designRefHeight)/2;
    }
    return {x: offsetX, y: offsetY};
  },

  scaleSpriteFill: function(sprite) {
    if(this.getDirection() == 'Horizontal'){
      sprite.setScale(this.width / sprite.width, this.height / sprite.height);
    } else{
      this.scaleSpriteFillVertical(sprite);
    }
  },  

  scaleSpriteFillHeight: function(sprite) {
    if(this.getDirection() == 'Horizontal'){
      sprite.setScale(this.vScale(), this.height / sprite.height);
    } else{
      sprite.setScale(this.vScale(), this.width / sprite.height);
    }
  }, 
  
  scaleSpriteFillWidth: function(sprite) {
    if(this.getDirection() == 'Horizontal'){
      sprite.setScale(this.width / sprite.width, this.vScale());
    } else{
      sprite.setScale(this.height / sprite.width, this.vScale());
    }
  }, 

  scaleSpriteByMin: function(sprite) {
    var rationScreen, screenWidth, screenHeight;
    if(this.getDirection() == 'Horizontal') {
      screenWidth = this.width;
      screenHeight = this.height;
    } else {
      screenWidth = this.height;
      screenHeight = this.width;
    }
    rationScreen = screenWidth/screenHeight;
    var rationSprite = sprite.width/sprite.height;
    var scale;
    if(rationScreen >= rationSprite){
      
      scale = screenHeight / sprite.height;
    } else{
      scale = screenWidth / sprite.width;
    }
    sprite.setScale(scale, scale);
  },  

  scaleSpriteByMax: function(sprite) {
    var rationScreen, screenWidth, screenHeight;
    if(this.getDirection() == 'Horizontal') {
      screenWidth = this.width;
      screenHeight = this.height;
    } else {
      screenWidth = this.height;
      screenHeight = this.width;
    }
    rationScreen = screenWidth/screenHeight;
    var rationSprite = sprite.width/sprite.height;
    var scale;
    if(rationScreen >= rationSprite){
      scale = screenWidth / sprite.width;
    } else{
      scale = screenHeight / sprite.height;
    }
    sprite.setScale(scale, scale);
  },  

  scaleBgSprite: function(sprite) {
    if(this.getDirection() == 'Vertical' && this.scaleMode == 'height'){
      sprite.setScale(this.height / (this._designRefHeight*1080/this._designRefWidth), this.width / this._designRefHeight);
    } else{
      sprite.setScale(this.width / this._designRefWidth, this.height / this._designRefHeight);
    }
  },

  scaleBgX: function(designX) {
    return designX * (this.width / this._designRefWidth);
  },

  scaleBgX: function (designX, _location) {
    let location = (_location || 'left').toLowerCase();
    let offset = 0;
    var width = this.width;
    if(this.getDirection() != 'Horizontal'){
      width = this.height;
    }
    if(location =='center'){
      offset = width/2;
    } else if(location =='right'){
      offset = width;
    }

    if(this.getDirection() == 'Horizontal'){
      //normal
      return offset + designX * this.hScale();
    } else{
      
      if(this.scaleMode == 'full'){
        return offset + designX * this.vScale();
      } else{
        return offset + designX * this.vScaleVertical();
      }
    }
  },

  scaleBgY: function(designY, _location) {
    let location = (_location || 'top').toLowerCase();
    let offset = 0;
    var height = this.height;
    if(this.getDirection() != 'Horizontal'){
      height = this.width;
    }
    if(location =='center'){
      offset = height/2;
    } else if(location =='bottom'){
      offset = height;
    }

    if(this.getDirection() == 'Horizontal') {
      return offset + designY * this.vScale();
    } else {
      return offset + designY * this.hScale();
    }
  },

  hScaleVertical: function() {
    return (this.height / this._designRefWidth);
  },

  vScaleVertical: function() {
    return (this.width / this._designRefHeight);
  },

  scaleSpriteByHeightVertical: function(sprite) {
    sprite.setScale(this.vScaleVertical(), this.vScaleVertical());
  },

  scaleSpriteFillVertical: function(sprite) {
    sprite.setScale(this.height / sprite.width, this.width / sprite.height);
  },  

  getDirection: function(){
    var direction = '';
    if(this.width >= this.height){
      direction = 'Horizontal';
    } else{
      direction = 'Vertical';
    }
    return direction;
  }
};

