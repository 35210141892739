'use strict';

// import previewImg from '../images/preview.png';
import timeImg from '../images/time.png';
import textStartLeftImg from '../images/text_start_left.png';
import textStartRightImg from '../images/text_start_right.png';
import copyrightLeftImg from '../images/copyright_left.png';
import copyrightRightImg from '../images/copyright_right.png';
import btnEnterImg from '../images/btn_enter.png';
import iconEnterImg from '../images/icon_enter.png';
import titleLeftImg from '../images/title_left.png';
import titleRightImg from '../images/title_right.png';

import prefaceTitleImg from '../images/preface_title.png';
import signatureImg from '../images/signature.png';
import btnBackImg from '../images/btn_back.png';
import btnNextImg from '../images/btn_next.png';
import maskTopImg from '../images/mask_top.png';
import maskBottomImg from '../images/mask_bottom.png';
import maskLeftImg from '../images/mask_left.png';
import leftMaskImg2 from '../images/left_mask_2.png';

import maskRectImg from '../images/mask_rect.png';
import maskPrefaceImg from '../images/mask_preface.png';
import maskPrefaceImg0 from '../images/mask_preface_0.png';
import maskPrefaceImg1 from '../images/mask_preface_1.png';
import maskPrefaceImg2 from '../images/mask_preface_2.png';

import maskListImg from '../images/mask_list.png';


import systemNameImg0 from '../images/system_names/0.png';
import systemNameImg1 from '../images/system_names/1.png';
import systemNameImg2 from '../images/system_names/2.png';
import systemNameImg3 from '../images/system_names/3.png';
import systemNameImg4 from '../images/system_names/4.png';
import systemNameImg5 from '../images/system_names/5.png';
import systemNameImg6 from '../images/system_names/6.png';
import systemNameImg7 from '../images/system_names/7.png';
import systemNameImg8 from '../images/system_names/8.png';
import systemNameImg9 from '../images/system_names/9.png';
import systemNameImg10 from '../images/system_names/10.png';
import systemNameImg11 from '../images/system_names/11.png';

import prefaceImg from '../images/preface.png';
// import titlePrefaceImg from '../images/title_preface.png';
// import titlePrefaceCNImg from '../images/title_preface_cn.png';
// import titlePrefaceENImg from '../images/title_preface_en.png';
// import contentPrefaceImg from '../images/content_preface.png';
// import contentPrefaceCNImg from '../images/content_preface_cn.png';
// import contentPrefaceENImg from '../images/content_preface_en.png';

import systemImg0 from '../images/systems/0.png';
import systemImg1 from '../images/systems/1.png';
import systemImg2 from '../images/systems/2.png';
import systemImg3 from '../images/systems/3.png';
import systemImg4 from '../images/systems/4.png';
import systemImg5 from '../images/systems/5.png';
import systemImg6 from '../images/systems/6.png';
import systemImg7 from '../images/systems/7.png';
import systemImg8 from '../images/systems/8.png';
import systemImg9 from '../images/systems/9.png';
import systemImg10 from '../images/systems/10.png';
import systemImg11 from '../images/systems/11.png';

import systemPrefaceNameImg0 from '../images/system_preface_names/0.png';
import systemPrefaceNameImg1 from '../images/system_preface_names/1.png';
import systemPrefaceNameImg2 from '../images/system_preface_names/2.png';
import systemPrefaceNameImg3 from '../images/system_preface_names/3.png';
import systemPrefaceNameImg4 from '../images/system_preface_names/4.png';
import systemPrefaceNameImg5 from '../images/system_preface_names/5.png';
import systemPrefaceNameImg6 from '../images/system_preface_names/6.png';
import systemPrefaceNameImg7 from '../images/system_preface_names/7.png';
import systemPrefaceNameImg8 from '../images/system_preface_names/8.png';
import systemPrefaceNameImg9 from '../images/system_preface_names/9.png';
import systemPrefaceNameImg10 from '../images/system_preface_names/10.png';
import systemPrefaceNameImg11 from '../images/system_preface_names/11.png';

import systemBlockImg0 from '../images/systems_block/0.png';
import systemBlockImg1 from '../images/systems_block/1.png';
import systemBlockImg2 from '../images/systems_block/2.png';
import systemBlockImg3 from '../images/systems_block/3.png';
import systemBlockImg4 from '../images/systems_block/4.png';
import systemBlockImg5 from '../images/systems_block/5.png';
import systemBlockImg6 from '../images/systems_block/6.png';
import systemBlockImg7 from '../images/systems_block/7.png';
import systemBlockImg8 from '../images/systems_block/8.png';
import systemBlockImg9 from '../images/systems_block/9.png';
import systemBlockImg10 from '../images/systems_block/10.png';
import systemBlockImg11 from '../images/systems_block/11.png';

import btnUndergraduateImg from '../images/btn_undergraduate.png';
import btnGraduateImg from '../images/btn_graduate.png';

import systemPrefaceImg0 from '../images/system_preface/0.png';
import systemPrefaceImg1 from '../images/system_preface/1.png';
import systemPrefaceImg2 from '../images/system_preface/2.png';
import systemPrefaceImg3 from '../images/system_preface/3.png';
import systemPrefaceImg4 from '../images/system_preface/4.png';
import systemPrefaceImg5 from '../images/system_preface/5.png';
import systemPrefaceImg6 from '../images/system_preface/6.png';
import systemPrefaceImg7 from '../images/system_preface/7.png';
import systemPrefaceImg8 from '../images/system_preface/8.png';
import systemPrefaceImg9 from '../images/system_preface/9.png';
import systemPrefaceImg10 from '../images/system_preface/10.png';
import systemPrefaceImg11 from '../images/system_preface/11.png';

import textSidebarImg0 from '../images/text_sidebar/0.png';
import textSidebarImg1 from '../images/text_sidebar/1.png';
import textSidebarImg2 from '../images/text_sidebar/2.png';
import textSidebarImg3 from '../images/text_sidebar/3.png';
import textSidebarImg4 from '../images/text_sidebar/4.png';
import textSidebarImg5 from '../images/text_sidebar/5.png';
import textSidebarImg6 from '../images/text_sidebar/6.png';
import textSidebarImg7 from '../images/text_sidebar/7.png';
import textSidebarImg8 from '../images/text_sidebar/8.png';
import textSidebarImg9 from '../images/text_sidebar/9.png';
import textSidebarImg10 from '../images/text_sidebar/10.png';
import textSidebarImg11 from '../images/text_sidebar/11.png';

import btnDepartmentsImg from '../images/btn_departments.png';
import btnChangeUndergraduateImg from '../images/btn_change_undergraduate.png';
import btnChangeGraduateImg from '../images/btn_change_graduate.png';
import btnStudentsImg from '../images/btn_students.png';
import btnRefreshImg from '../images/btn_refresh.png';


import bgImg from '../images/bg_wh.png';
import sidebarBgBlueImg from '../images/sidebar_bg_blue.png';
import sidebarBgPinkImg from '../images/sidebar_bg_pink.png';

import tipMaskImg from '../images/tip_mask.png';
import iconSlideBlueImg from '../images/icon_slide_blue.png';
import iconSlidePinkImg from '../images/icon_slide_pink.png';
import btnCloseBlueImg from '../images/btn_close_blue.png';
import btnClosePinkImg from '../images/btn_close_pink.png';
import textStartingImg from '../images/text_starting.png';
import textEndingImg from '../images/text_ending.png';
import endingBgBlueImg from '../images/ending_bg_blue.png';
import endingBgPinkImg from '../images/ending_bg_pink.png';

import btnPortfolioBlueImg from '../images/btn_portfolio_blue.png';
import btnPortfolioPinkImg from '../images/btn_portfolio_pink.png';
import btnClosePortfolioImg from '../images/btn_close_portfolio.png';
import btnLeftPortfolioImg from '../images/btn_left_portfolio.png';
import btnRightPortfolioImg from '../images/btn_right_portfolio.png';
// import btnShareImg from '../images/btn_share.png';
// import btnRectLeftImg from '../images/bg_rect_left.png';
// import btnRectRightImg from '../images/bg_rect_right.png';
import bgDotWorkInfoImg from '../images/bg_dot_work_info.png';

import numberRegionImg from '../images/number_region.png';
import numberRegionXml from '../images/number_region.xml';

import numberPortfolioImg from '../images/number_portfolio.png';
import numberPortfolioXml from '../images/number_portfolio.xml';

import timeFontImg from '../images/time_font.png';
import timeFontXml from '../images/time_font.xml';

import btnZoomInImg from '../images/btn_zoom_in.png';
import btnZoomOutImg from '../images/btn_zoom_out.png';
import iconZoomProgressImg from '../images/icon_zoom_progress.png';

import hotspotImg from '../images/hotspot.png';
import hotspotSlideImg from '../images/hotspot_slide.png';
import btnPlayImg from '../images/btn_play.png';

import iconVideoProgressImg from '../images/icon_video_progress.png';
import controllerPlayImg from '../images/controller_play.png';
import controllerPauseImg from '../images/controller_pause.png';
import controllerLoadingImg from '../images/controller_loading.png';

import lineBlueImg from '../images/line_blue.png';
import linePinkImg from '../images/line_pink.png';

import startImg from '../archives/start/0.png';
import animationBgImg from '../archives/bg_new/0.jpg';

var ScenePreload = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function ScenePreload ()
  {
    Phaser.Scene.call(this, { key: 'scenePreload', active: false });

    this.photoSourcesLoaded = false;
    this.photoSourcesAdded = false;
  },

  init: function(params){
    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.params = params;
  },

  preload: function ()
  {

    if(this.params.personal) {
      // console.log(this.params.personal);
      this.load.image(this.params.personal.texture, this.params.personal.path);  
    }
    if(this.params.system) {
      // console.log(this.params);
      var systemNameImgs = [
        systemNameImg0, systemNameImg1, systemNameImg2, systemNameImg3, systemNameImg4,
        systemNameImg5, systemNameImg6, systemNameImg7, systemNameImg8, systemNameImg9,
        systemNameImg10, systemNameImg11
      ];
      const index = this.params.systemIndex;
      this.load.image('system_name', systemNameImgs[index]);
    }

    
    // this.load.image('preview', previewImg);
    this.load.image('time', timeImg);
    this.load.image('text_start_left', textStartLeftImg);
    this.load.image('text_start_right', textStartRightImg);
    this.load.image('copyright_left', copyrightLeftImg);
    this.load.image('copyright_right', copyrightRightImg);
    this.load.image('btn_enter', btnEnterImg);
    this.load.image('icon_enter', iconEnterImg);
    this.load.image('title_left', titleLeftImg);
    this.load.image('title_right', titleRightImg);
    
    this.load.image('preface_title', prefaceTitleImg);
    this.load.image('signature', signatureImg);
    this.load.image('btn_back', btnBackImg);
    this.load.image('btn_next', btnNextImg);
    this.load.image('mask_top', maskTopImg);
    this.load.image('mask_bottom', maskBottomImg);
    this.load.image('mask_left', maskLeftImg);
    this.load.image('left_mask_2', leftMaskImg2);

    
    this.load.image('mask_rect', maskRectImg);
    this.load.image('mask_preface', maskPrefaceImg);
    this.load.image('mask_preface_0', maskPrefaceImg0);
    this.load.image('mask_preface_1', maskPrefaceImg1);
    this.load.image('mask_preface_2', maskPrefaceImg2);
    this.load.image('mask_list', maskListImg);


    this.load.image('preface', prefaceImg);
    // this.load.image('title_preface', titlePrefaceImg);
    // this.load.image('title_preface_cn', titlePrefaceCNImg);
    // this.load.image('title_preface_en', titlePrefaceENImg);
    // this.load.image('content_preface', contentPrefaceImg);
    // this.load.image('content_preface_cn', contentPrefaceCNImg);
    // this.load.image('content_preface_en', contentPrefaceENImg);

    var systemImgs = [systemImg0, systemImg1, systemImg2, systemImg3, systemImg4,
      systemImg5, systemImg6, systemImg7, systemImg8, systemImg9,
      systemImg10, systemImg11];
    systemImgs.forEach((img, index) => {
      this.load.image('system_' + index, img);
    });

    var systemPrefaceNameImgs = [systemPrefaceNameImg0, systemPrefaceNameImg1, systemPrefaceNameImg2, systemPrefaceNameImg3, systemPrefaceNameImg4,
      systemPrefaceNameImg5, systemPrefaceNameImg6, systemPrefaceNameImg7, systemPrefaceNameImg8, systemPrefaceNameImg9,
      systemPrefaceNameImg10, systemPrefaceNameImg11];
    systemPrefaceNameImgs.forEach((img, index) => {
      this.load.image('system_preface_name_' + index, img);
    });

    var systemBlockImgs = [systemBlockImg0, systemBlockImg1, systemBlockImg2, systemBlockImg3, systemBlockImg4,
      systemBlockImg5, systemBlockImg6, systemBlockImg7, systemBlockImg8, systemBlockImg9,
      systemBlockImg10, systemBlockImg11];
    systemBlockImgs.forEach((img, index) => {
      this.load.image('system_block_' + index, img);
    });

    this.load.image('btn_undergraduate', btnUndergraduateImg);
    this.load.image('btn_graduate', btnGraduateImg);

    var systemPrefaceImgs = [
      systemPrefaceImg0, systemPrefaceImg1, systemPrefaceImg2, systemPrefaceImg3,
      systemPrefaceImg4, systemPrefaceImg5, systemPrefaceImg6, systemPrefaceImg7,
      systemPrefaceImg8, systemPrefaceImg9, systemPrefaceImg10, systemPrefaceImg11
    ];
    systemPrefaceImgs.forEach((img, index) => {
      this.load.image('system_preface_' + index, img);
    });

    var textSidebarImgs = [
      textSidebarImg0, textSidebarImg1, textSidebarImg2, textSidebarImg3,
      textSidebarImg4, textSidebarImg5, textSidebarImg6, textSidebarImg7,
      textSidebarImg8, textSidebarImg9, textSidebarImg10, textSidebarImg11
    ];
    textSidebarImgs.forEach((img, index) => {
      this.load.image('text_sidebar_' + index, img);
    });

    this.load.image('btn_departments', btnDepartmentsImg);
    this.load.image('btn_change_undergraduate', btnChangeUndergraduateImg);
    this.load.image('btn_change_graduate', btnChangeGraduateImg);
    this.load.image('btn_students', btnStudentsImg);
    this.load.image('btn_refresh', btnRefreshImg);

    this.load.image('bg', bgImg);
    this.load.image('sidebar_bg_blue', sidebarBgBlueImg);
    this.load.image('sidebar_bg_pink', sidebarBgPinkImg);
    this.load.image('tip_mask', tipMaskImg);
    this.load.image('icon_slide_blue', iconSlideBlueImg);
    this.load.image('icon_slide_pink', iconSlidePinkImg);
    this.load.image('btn_close_blue', btnCloseBlueImg);
    this.load.image('btn_close_pink', btnClosePinkImg);
    this.load.image('text_starting', textStartingImg);
    this.load.image('text_ending', textEndingImg);
    this.load.image('ending_bg_blue', endingBgBlueImg);
    this.load.image('ending_bg_pink', endingBgPinkImg);

    this.load.image('btn_portfolio_blue', btnPortfolioBlueImg);
    this.load.image('btn_portfolio_pink', btnPortfolioPinkImg);
    this.load.image('btn_close_portfolio', btnClosePortfolioImg);
    this.load.image('btn_left_portfolio', btnLeftPortfolioImg);
    this.load.image('btn_right_portfolio', btnRightPortfolioImg);
    // this.load.image('btn_share', btnShareImg);
    // this.load.image('bg_rect_left', btnRectLeftImg);
    // this.load.image('bg_rect_right', btnRectRightImg);
    this.load.image('bg_dot_work_info', bgDotWorkInfoImg);
    this.load.bitmapFont('numberRegionFont', numberRegionImg, numberRegionXml);
    this.load.bitmapFont('numberPortfolioFont', numberPortfolioImg, numberPortfolioXml);

    this.load.bitmapFont('timeFont', timeFontImg, timeFontXml);
    
    this.load.image('btn_zoom_in', btnZoomInImg);
    this.load.image('btn_zoom_out', btnZoomOutImg);
    this.load.image('icon_zoom_progress', iconZoomProgressImg);

    // load hotspot
    this.load.image('hotspot', hotspotImg);
    this.load.image('hotspot_slide', hotspotSlideImg);
    this.load.image('btn_play', btnPlayImg);

    this.load.image('icon_video_progress', iconVideoProgressImg);
    this.load.image('controller_play', controllerPlayImg);
    this.load.image('controller_pause', controllerPauseImg);
    this.load.image('controller_loading', controllerLoadingImg);

    this.load.image('line_blue', lineBlueImg);
    this.load.image('line_pink', linePinkImg);

    
    // this.load.spritesheet('start', startImg, {frameWidth: 660, frameHeight: 660});

    // 首页动画第一帧
    // this.load.image('start', startImg);
    this.load.image('animation_bg', animationBgImg);
    

    this.scene.launch('sceneLoading');
    this.isReady = false;
    
    this.load.on('progress', function (progress, e){
      this.scene.get('sceneLoading').setLoadingProgress(parseInt(progress*100));
      if(progress >= 1) {
        this.isReady = true;
      }
    }, this);
    
  },

  create: function ()
  {
  },

  update: function(){
    if(this.isReady){
      this.isReady = false;

      this.scene.launch('sceneTitle');
      this.scene.launch('sceneBottomLine');

      this.scene.launch('sceneEnter', this.params);
      this.scene.launch('scenePreview');
      // this.scene.launch('sceneSideBar');
    }

    // if(!startImageLoaded) {
    //   let loadedNumber = 0;
    //   for(var i = 0; i < this.startImagedNumber; i ++) {
    //     if(this.textures.getTextureKeys().indexOf('start_' + i) >= 0 ) {
    //       loadedNumber ++;
    //     }
    //   }
  
    //   if(loadedNumber >= this.startImagedNumber) {
    //     startImageLoaded = true;
    //     this.scene.stop();
    //   }
    // }

    if(!bgImageLoaded) {
      let loadedNumber = 0;
      for(var i = 0; i < this.bgImagedNumber; i ++) {
        if(this.textures.getTextureKeys().indexOf('animation_bg_' + i) >= 0 ) {
          loadedNumber ++;
        }
      }
  
      if(loadedNumber >= this.bgImagedNumber) {
        bgImageLoaded = true;
        this.scene.stop();
        animationStart();
      }
    }
  },

  loadAnimationImages: function() {
    // this.startImagedNumber = 70;
    // for(let i = 0; i < this.startImagedNumber; i ++) {
    //   this.load.image(
    //     'start_' + i,
    //     require('../archives/start/' + i + '.png').default
    //   );
    // }

    // return
    this.bgImagedNumber = 71;
    for(let i = 0; i < this.bgImagedNumber; i ++) {
      this.load.image(
        'animation_bg_' + i,
        require('../archives/bg_new/' + i + '.jpg').default
      );
    }

    // this.startImagedNumber = 101;
    // for(let i = 0; i < this.startImagedNumber; i ++) {
    //   this.load.spritesheet(
    //     'start_' + i,
    //     require('../archives/start_500_4/' + i + '.png').default,
    //     // {frameWidth: 789, frameHeight: 660}
    //     {frameWidth: 598, frameHeight: 500},
    //     // {frameWidth: 480, frameHeight: 400},
    //     // {frameWidth: 359, frameHeight: 300}
    //     // {frameWidth: 239, frameHeight: 200}
    //   );
    // }
    // for(let i = 0; i < this.startImagedNumber; i ++) {
    //   this.load.spritesheet(
    //     'start_pink_' + i,
    //     require('../archives/start_pink/' + i + '.png').default,
    //     {frameWidth: 395, frameHeight: 660}
    //     // {frameWidth: 794, frameHeight: 800}
    //   );
    // // }
    this.load.removeAllListeners();
    this.load.start();
  }

});
export default ScenePreload;